<app-uni-page  [parent]="this" pageTitle="Manufacturer ProductType Configs"
  [bodyTemplate]="bodyTemplate" [footerTemplate]="footerTemplate">
</app-uni-page>

<ng-template #bodyTemplate>
    <app-ag-grid [gridOptions]="mptConfigGridOptions"  ></app-ag-grid>
</ng-template>

<ng-template #footerTemplate >
  <div class="left-buttons">
    <button mat-raised-button [disabled]="!this.user.isProductAdmin" (click)="addMptConfig()">
      <span>Add New Manufacturer Product Type Config</span>
    </button>
  </div>
  <div>
    <button mat-raised-button (click)="goBack()">
      <span>Go Back</span>
    </button>
  </div>
</ng-template>
