import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ActiveStatus, ManufacturerImage, ManufacturerProductTypeConfig, ManufacturerProductTypeConfigAddon } from 'app/model/entities/entity-model';
import { AuthService, AuthUser } from 'app/shared/auth.service';
import { BusyService } from 'app/shared/busy.service';
import { DbQueryService } from 'app/shared/db-query.service';
import { DbSaveService } from 'app/shared/db-save.service';
import { DialogService } from 'app/shared/dialog.service';
import { UnitOfWork } from 'app/shared/unit-of-work';
import { ProductTypeFinderDialogComponent } from './product-type-finder-dialog.component';
import { ActiveStatusCode } from 'app/model/enums/active-status-code';
import { GridOptions } from '@ag-grid-community/core';
import { AgFns } from 'app/shared/ag-fns';
import { AddonFinderDialogComponent } from 'app/shared/addon-finder-dialog.component';
import { EntityFns } from 'app/shared/entity-fns';

@Component({
  templateUrl: './edit-manufacturer-product-type-config-dialog.component.html',
//  encapsulation: ViewEncapsulation.None
})
export class EditManufacturerProductTypeConfigDialogComponent {
  
  user: AuthUser;
  mptConfig: ManufacturerProductTypeConfig;

  mptcAddonGridOptions: GridOptions;
  mptcAddons: ManufacturerProductTypeConfigAddon[];

  activeStatuses: ActiveStatus[];

  isAdding: boolean;
  title: string;
  isPageReady = false;


  static async show(matDialog: MatDialog, data: any) {
    return await matDialog
      .open(EditManufacturerProductTypeConfigDialogComponent, {
        disableClose: true,
        height: '800px',
        width: '900px',
        data: data,
      })
      .afterClosed()
      .toPromise();
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<EditManufacturerProductTypeConfigDialogComponent>,
    private dbQueryService: DbQueryService, private dbSaveService: DbSaveService, private uow: UnitOfWork,
    private authService: AuthService, private dialogService: DialogService, public busyService: BusyService,
    protected matDialog: MatDialog,
  ) {

    this.user = this.authService.getUser();

    this.mptConfig = this.data.manufacturerProductTypeConfig;
    if (this.mptConfig == null) {
      this.isAdding = true;
      this.mptConfig = this.createMptConfig();
    }
    this.mptcAddons = this.mptConfig.manufacturerProductTypeConfigAddons;

    const title = 'Manufacturer Product Type Config';
    this.title = this.isAdding ? 'Creating New  ' + title: 'Editing ' + title;

    this.prepare();
  }

  private createMptConfig() {
    const mptConfig = this.uow.createEntity(ManufacturerProductTypeConfig, {
      activeStatusId: ActiveStatusCode.Active
    });
    
    return mptConfig;
  }

  private async prepare() {
    this.activeStatuses = this.dbQueryService.getAllCached(ActiveStatus);

    this.mptcAddonGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onAddonGridReady,
      
      rowModelType: 'clientSide',
      
    });
    

    this.isPageReady = true;
  }

  onAddonGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Name and Location', field: 'addon.nameAndLocation',  filter: 'agTextColumnFilter'  },
      { headerName: 'Station', field: 'addon.addonStation.name', filter: 'agTextColumnFilter' },
      { headerName: 'Instructions', field: 'addon.instructions', filter: 'agTextColumnFilter' },
      { headerName: 'Additional Info', field: 'additionalInfo', filter: 'agTextColumnFilter', 
        editable: this.isAdditionalInfoEditable, cellStyle: (p) => this.isAdditionalInfoEditable(p) && AgFns.setEditableStyle(p)},
      // Not yet supported.
      { ...AgFns.createButtonProps('', this.detachAddon.bind(this), { label: 'Detach', icon: 'delete' }) }
    ];
    const sortModel = [
        { colId: 'nameAndLocation', sort: 'asc' as const },
      ];

    AgFns.initGrid(this.mptcAddonGridOptions, colDefs, sortModel);

    
    AgFns.applyGridRouteParams(this.mptcAddonGridOptions);
  }

  isAdditionalInfoEditable(p: any) {
    return p.data.addon.needsAdditionalInfo;
  }

  async selectProductType() {
    const r = await ProductTypeFinderDialogComponent.show(this.matDialog, {  rowSelection: 'single' });
    if (r) {
      this.mptConfig.productTypeId = r[0].id;
    }
  }

  async attachAddon() {
    const addons = await AddonFinderDialogComponent.show(this.matDialog, { accountId: null } );
    if (addons.length == 0) {
      return;
    }
    const mptConfigAddon = this.uow.createEntity(ManufacturerProductTypeConfigAddon, {
      addonId: addons[0].id,
      manufacturerProductTypeConfigId: this.mptConfig.id
    });
    AgFns.refreshGrid(this.mptcAddonGridOptions, this.mptcAddons);
  }

  detachAddon(mptcAddon: ManufacturerProductTypeConfigAddon) {
    const aspect = mptcAddon.entityAspect;
    if (aspect.entityState.isAdded()) {
      aspect.setDetached();
    } else {
      aspect.setDeleted();
    }
    AgFns.refreshGrid(this.mptcAddonGridOptions, this.mptcAddons);
  }

  async ok() {
    const ok = await this.save();
    if (!ok) {
      return;
    }
    this.dialogRef.close(true);
  }

  async save() {
    // remove excess spaces

    const isValid = await this.validate();
    if (!isValid) { return false; }

    try {
      const sr = await this.dbSaveService.saveChanges();
      return true;
    } catch (e) {
      return false;
    }
  }

  async validate() {

    // TODO: add this later.
    let matchingEntity = await this.dbQueryService.getMatchingManufacturerProductTypeConfig(this.mptConfig);
    if (matchingEntity != null) {
      await this.dialogService.showOkMessage('Duplicate Name', `Cannot save because this config's name is a duplicate of an existing name`);
      return false;
    }

    const ea = this.mptConfig.entityAspect;
    if (! ea.validateEntity()) {
      const vErrors = ea.getValidationErrors();
      const errMsg = vErrors.map(ve => ve.errorMessage).join('<br>');
      await this.dialogService.showOkMessage('Validation Error(s)', errMsg);
      return false;
    }

    return true;
  }

  async cancel() {
    this.isPageReady = false;
    // entityAspect may not exist if it is a new object
    this.mptConfig.manufacturerProductTypeConfigAddons.slice().forEach(x => x.entityAspect.rejectChanges());
    this.mptConfig.entityAspect?.rejectChanges();

    this.dialogRef.close(false);
  }

  /** Create the join entity between Image and parent entity */
  createImageJoinEntity(event: { imageId: number, isPrimary: boolean }): void {
    this.dbSaveService.uow.createEntity(ManufacturerImage, {
      manufacturerId: this.mptConfig.id,
      imageId: event.imageId,
      isPrimary: event.isPrimary
    });
  }

}
