import { GridOptions, RowDoubleClickedEvent } from '@ag-grid-community/core';
import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ProductType, Product } from 'app/model/entities/entity-model';
import { AgFns, ISortModel } from 'app/shared/ag-fns';
import { DbQueryService } from 'app/shared/db-query.service';
import { DialogService } from 'app/shared/dialog.service';


@Component({
  templateUrl: './product-type-finder-dialog.component.html'
})
export class ProductTypeFinderDialogComponent {
  
  istGridOptions: GridOptions;
  

  static async show(matDialog: MatDialog, data: any) {
    const r = await matDialog.open(ProductTypeFinderDialogComponent, {
      disableClose: true,
      height: '530px',
      width: '800px',
      data: data
    })
      .afterClosed().toPromise();
    return <ProductType[]>r;
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ProductTypeFinderDialogComponent>,
    private dbQueryService: DbQueryService, private dialogService: DialogService) {

    this.prepare();

  }

  async prepare() {
    this.istGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onIstGridReady,
      onRowDoubleClicked: this.onIssRowDoubleClick,
      rowSelection: this.data?.rowSelection ?? 'multiple',
      rowModelType: 'serverSide',
    })
  }

  onIstGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Manufacturer', field: 'manufacturer.name',  filter: 'agTextColumnFilter'  },
      { headerName: 'Description', field: 'description', filter: 'agTextColumnFilter' },
      { headerName: 'SKU', field: 'style', filter: 'agTextColumnFilter' },
    ];
    const sortModel = [
      { colId: 'manufacturer.name', sort: 'asc' },
      { colId: 'description', sort: 'asc' },
      { colId: 'style', sort: 'asc' },
      
    ] as ISortModel;
    AgFns.initGrid(this.istGridOptions, colDefs, sortModel);

    this.updateDatasource();
  }

  updateDatasource() {
    const gridApi = this.istGridOptions && this.istGridOptions.api;
    if (gridApi == null) {
      return;
    }

    let q = this.dbQueryService.createProductTypeFinderQuery();
    
    const ds = AgFns.buildDatasource(() => q);
    gridApi.setServerSideDatasource(ds);
  }


  onIssRowDoubleClick(e: RowDoubleClickedEvent) {
    if (!e.node.isSelected()) {
      return;
    }
    const ist = e.data as ProductType;
    this.dialogRef.close([ist]);
  }

  ok() {
    const r = this.istGridOptions.api.getSelectedRows() as ProductType[];
    this.dialogRef.close(r);
  }

  cancel() {
    this.dialogRef.close(null);
  }

}
