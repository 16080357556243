
<app-uni-dialog title="Quickbooks Account Configuration" [parent]="this" [contentTemplate]="contentTemplate" [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>
  <h1>Accounts</h1>
  Choose a Quickbooks account from the list for each of the account types below.
  These accounts will be used when Quickbooks transactions are created from Uniformax.
  If an account is not in the list, it needs to be set up in Quickbooks first.
  <div uni-flex-row *ngFor="let c of qbAccounts">
    <span style="width: 20%">{{c.name}}</span>
    <mat-form-field style="width: 300px">
      <mat-select [(ngModel)]="c.qbAccountId" placeholder="Account" (selectionChange)="onAccountChange($event, c)">
        <mat-option [value]="act.Id" *ngFor="let act of getAccountsByType(c.qbType)">
          {{act.Name}} <span style="color: gray">({{act.AccountSubType}})</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
    {{c.qbType}}
  </div>

  <h1>Items</h1>
  Choose a Quickbooks item from the list for each of the types below.
  These items will appear on invoices in Quickbooks that are created from Uniformax.
  Each item has an associated income account related to it; these can be changed in Quickbooks.
  If an item is not in the list, it needs to be set up in Quickbooks first.
  <div uni-flex-row *ngFor="let c of qbItems">
    <span style="width: 20%">{{c.name}}</span>
    <mat-form-field style="width: 300px">
      <mat-select [(ngModel)]="c.qbItemId" placeholder="Item" (selectionChange)="onItemChange($event, c)">
        <mat-option [value]="it.Id" *ngFor="let it of getItemsByType(c.qbType)">
          {{it.Name}} <span style="color: gray">(Account: {{it.IncomeAccountRef?.name}})</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
    {{c.qbType}}
  </div>

</ng-template>

<ng-template #footerTemplate>
  <button mat-raised-button [disabled]="dbSaveService.isSaving || !uow.hasChanges()" (click)="save()">Save & Exit
  </button>
  <button mat-raised-button [disabled]="dbSaveService.isSaving || !uow.hasChanges()" (click)="undo()">Undo Changes
  </button> 
  <button mat-raised-button [disabled]="dbSaveService.isSaving" (click)="close()">Close
  </button>
</ng-template>
