// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { CancellationReasonType } from './cancellation-reason-type';
import { CancellationRequest } from './cancellation-request';
import { CancellationRequestDetailStatus } from './cancellation-request-detail-status';
import { JoDetail } from './jo-detail';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class CancellationRequestDetail extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  cancellationExplanation: string;
  cancellationReasonTypeId: number;
  cancellationRequestDetailStatusId: number;
  cancellationRequestId: number;
  cancellationStatusExplanation: string;
  cancellationStatusTs: Date;
  joDetailId: number;
  proximityCancellationRequestDetailId: string;
  proximityOrigCancellationRequestDetailId: string;
  qty: number;
  cancellationReasonType: CancellationReasonType;
  cancellationRequest: CancellationRequest;
  cancellationRequestDetailStatus: CancellationRequestDetailStatus;
  joDetail: JoDetail;
}

