<app-uni-dialog [parent]="this" [contentTemplate]="contentTemplate" [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>

  <div class="mt-12" uni-flex-row>
    
    <div class="">
      <button  mat-raised-button class="accent-blue" style="margin-bottom: 20px;" (click)="selectProductType()"  >
        <span>Select Product Type</span>
      </button>
    </div>
  </div>
  <div uni-flex-row>
    <mat-form-field style="width: 400px;">
      <input matInput placeholder="Manufacturer" [ngModel]="mptConfig.productType?.manufacturer.name" readonly="readonly" />
    </mat-form-field>
    <mat-form-field style="width: 400px;" [matTooltip]="mptConfig.productType?.style">
      <input matInput placeholder="Style" [ngModel]="mptConfig.productType?.style" readonly="readonly" />
    </mat-form-field>
  </div>
  <div uni-flex-row>
    <mat-form-field style="width: 830px;">
      <input matInput placeholder="Description" [ngModel]="mptConfig.productType?.description" readonly="readonly" />
    </mat-form-field>
  </div>

    
  
  <div uni-flex-row>
    <mat-form-field style="width: 400px;" [matTooltip]="mptConfig.configName">
      <input matInput placeholder="Manufacturer Config Name" [(ngModel)]="mptConfig.configName"  />
    </mat-form-field>
    <mat-checkbox class="ml-4" [(ngModel)]="mptConfig.isForStockOrdersOnly" name="isForStockOrdersOnly">For Stock Orders Only</mat-checkbox>
    </div>

  <div uni-flex-row>
    <mat-form-field style="width: 830px;" [matTooltip]="mptConfig.configDescription">
      <input matInput placeholder="Manufacturer Config Description" [(ngModel)]="mptConfig.configDescription"  />
    </mat-form-field>
  </div>

  <div uni-flex-row>
    <mat-form-field style="width: 200px;" [matTooltip]="mptConfig?.activeStatus?.name">
      <mat-select [disabled]="!this.user.isProductAdmin" [(ngModel)]="mptConfig.activeStatusId" placeholder="Status">
        <mat-option [value]="activeStatus.id" *ngFor="let activeStatus of activeStatuses">
          {{activeStatus.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button  mat-raised-button class="accent-blue" style="margin-bottom: 20px;" (click)="attachAddon()"  >
      <span>Attach Addon</span>
    </button>
  </div>

  <div class="content-card" style="height: calc(100% - 450px)" >
    <app-ag-grid  [gridOptions]="mptcAddonGridOptions" [rowData]="mptcAddons"> </app-ag-grid>
  </div>
</ng-template>

<ng-template #footerTemplate>
  <button mat-raised-button (click)="ok()">Ok
  </button>
  <button mat-raised-button (click)="cancel()">Cancel
  </button>
</ng-template>