import { GetRowIdParams, GridOptions, RowSelectedEvent } from '@ag-grid-community/core';
import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { JoDetail, JoDetailAddon, JoHeader, JoPullBatch, PoDetail, PoHeader } from 'app/model/entities/entity-model';
import { JoStatusCode } from 'app/model/enums/jo-status-code';
import { PoStatusCode } from 'app/model/enums/po-status-code';
import { AgFns } from 'app/shared/ag-fns';
import { FileViewDialogComponent, FileViewDialogData } from 'app/shared/file-view-dialog.component';
import { JoUtilsService } from 'app/shared/jo-utils.service';
import { PoUtilsService } from 'app/shared/po-utils.service';
import { TemplateRendererComponent } from 'app/shared/template-renderer.component';
import { UtilFns } from 'app/shared/util-fns';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { DomainBaseComponent } from '../shared/domain-base.component';
import { DomainService } from '../shared/domain.service';
import { ChangeJoPullBatchDialogComponent } from './change-jo-pull-batch-dialog.component';
import { UnapplyJodDialogComponent } from './unapply-jod-dialog.component';
import { DateFns } from 'app/shared/date-fns';
import { BarcodePrintStatusCode } from 'app/model/enums/barcode-print-status-code';
import { AddonBinStatusCode } from 'app/model/enums/addon-bin-status-code';
import { BarcodeService } from 'app/shared/barcode.service';
import { AgCheckboxCellComponent } from 'app/shared/ag-checkbox-cell.component';

@Component({
  selector: 'app-jo-pull-to-wip',
  templateUrl: './jo-pull-to-wip.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class JoPullToWipComponent extends DomainBaseComponent implements OnInit {
  @ViewChild('applyItemDetailsCell') applyItemDetailsCell: TemplateRef<any>;
  @ViewChild('deleteJoHeaderFromBatchCell') deleteJoHeaderFromBatchCell: TemplateRef<any>;
    @ViewChild('viewDocCell') viewDocCell: TemplateRef<any>;

  shouldPrintBarcodes: boolean = true;
  joHeaders: JoHeader[] = [];
  johGridOptions: GridOptions;

  joDetails: JoDetail[] = [];
  jodGridOptions: GridOptions;

  joPullBatch: JoPullBatch;
  joPullBatchId: number;

  isBatchDone: boolean;
  

  constructor(protected domainService: DomainService, protected route: ActivatedRoute, private matDialog: MatDialog,
    private joUtilsService: JoUtilsService, private poUtilsService: PoUtilsService, private barcodeService: BarcodeService) {
    super(domainService);

    this.route.queryParamMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
  }

  async updateFromContext() {
    this.johGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onJohGridReady,
      onRowSelected: this.onJohRowSelected,
    });

    this.jodGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onJodGridReady,
      rowSelection: 'multiple'
    }, { detailProperty: 'joDetailAddons' });

    const joPullBatchId = +this.route.snapshot.params['joPullBatchId'];
    var result = this.route.snapshot.queryParams['shouldPrintBarcodes'] == 'false'
    this.shouldPrintBarcodes = !result

    await this.updateForBatch(joPullBatchId);
  }

  async updateForBatch(joPullBatchId: number) {
    this.joPullBatchId = joPullBatchId;
    this.joDetails = await this.dbQueryService.getJoDetailsByJoPullBatchId(this.joPullBatchId);
    this.joHeaders = _.uniq(this.joDetails.map(jod => jod.joHeader));
    this.isBatchDone = this.joHeaders.some(joh => joh.joStatusId !== JoStatusCode.OpenProcessIncomplete);
    this.isPageReady = true;
  }

  onJohGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Job Order Id', field: 'id', filter: 'agNumberColumnFilter' },
      {
        headerName: 'Delete', colId: 'deleteJoh', cellRenderer: TemplateRendererComponent,
        cellRendererParams: {
          ngTemplate: this.deleteJoHeaderFromBatchCell
        }
      },
      { headerName: 'Account', field: 'account.accountName', filter: 'agTextColumnFilter' },
      { headerName: 'Status', field: 'joStatus.name', filter: 'agTextColumnFilter' },
      { headerName: 'JO Date', field: 'crtnTs', filter: 'agDateColumnFilter' },
      { headerName: 'Billing Address', field: 'billingAddress1', filter: 'agTextColumnFilter' },
      { headerName: 'City', field: 'billingCity', filter: 'agTextColumnFilter' },
      { headerName: 'State', field: 'billingState' },
      { headerName: 'EntryDate', field: 'entryDate' },
    ];
    this.johGridOptions.getRowId = (params: GetRowIdParams) => params.data?.id?.toString();
    AgFns.initGrid(this.johGridOptions, colDefs, null, true);
  }

  onJodGridReady(evt: any) {
    const colDefs = [
      { headerName: 'JO Detail Id', field: 'id', cellRenderer: 'agGroupCellRenderer' },
      {
        headerName: 'Apply', colId: 'add', cellRenderer: TemplateRendererComponent,
        cellRendererParams: {
          ngTemplate: this.applyItemDetailsCell
        }
      },
      { headerName: 'Manufacturer', field: 'product.productType.manufacturer.name', filter: 'agTextColumnFilter' },
      { headerName: 'Description', field: 'product.productType.description', filter: 'agTextColumnFilter' },
      { headerName: 'SKU', field: 'product.productType.style', filter: 'agTextColumnFilter' },
      { headerName: 'Features', field: 'product.featureChoicesExtract', filter: 'agTextColumnFilter' },
      { headerName: 'Order Qty', field: 'orderQty', filter: 'agNumberColumnFilter' },
      { headerName: 'Cancel Qty', field: 'cancelQty', filter: 'agNumberColumnFilter' },
      { headerName: 'Return Qty', field: 'returnQty', filter: 'agNumberColumnFilter' },
      {
        headerName: 'Applied Qty', type: 'numericColumn',
        headerTooltip: '(calc) Quantities already pulled or received',
        valueGetter: params => {
          const jod = <JoDetail>params.data;
          return jod.getItemDetailsRcvdQty();
        }
      },
      {
        headerName: 'PO Qty', type: 'numericColumn',
        headerTooltip: '(calc) PO Detail quantities not yet received or canceled',
        valueGetter: params => {
          const jod = <JoDetail>params.data;
          return jod.getPoDetailsRemainingQty();
        }
      },
      {
        headerName: 'Net Qty',
        headerTooltip: '(calc) The Net quantities remaining after all itemDetails and unreceived purchase order details have been accounted for',
        valueGetter: params => {
          const jod = <JoDetail>params.data;
          return jod.getRemainingQty();
        }
      },
      { headerName: 'Needs Barcode', cellRenderer: AgCheckboxCellComponent, editable: false,
        valueGetter: params => {
          const jod = <JoDetail>params.data;
          const needsBarcode = jod.itemDetails.some(itd => itd.barcodePrintStatusId == BarcodePrintStatusCode.None);
          return needsBarcode;
        }
      },
      { headerName: 'Job Order', field: 'joHeaderId', filter: 'agNumberColumnFilter' },
    ];
    const sortFields = ['product.productType.manufacturer.name', 'product.productType.description', 'product.productType.style'];
    const sortModel = sortFields.map(sf => {
      return { colId: sf, sort: 'asc' as const };
    });
    this.jodGridOptions.getRowId = (params: GetRowIdParams) => params.data?.id?.toString();
    this.updateJodMasterDetail(this.jodGridOptions);
    AgFns.initGrid(this.jodGridOptions, colDefs, sortModel, true);
  }

  updateJodMasterDetail(parentGridOptions: GridOptions) {
    const detailGridOptions = AgFns.createDetailGridOptions();
    detailGridOptions.columnDefs = [
      { headerName: 'Addon', field: 'addon.nameAndLocation' },
      { headerName: 'Addl. Info', field: 'additionalInfo' },
      { headerName: 'Price/Unit', field: 'unitPriceAmt' },
      { ...AgFns.createCellButtonProps('Documents', this.viewDocCell)  },
      
    ];
    AgFns.updateColDefs(detailGridOptions.columnDefs);
    parentGridOptions.detailCellRendererParams = {
      detailGridOptions: detailGridOptions,
      getDetailRowData: params => {
        const jod = params.data as JoDetail;
        params.successCallback(jod.joDetailAddons);
      },
    };
  }

  async onJohRowSelected(e: RowSelectedEvent) {
    // check if a deselect event and ignore
    if (!e.node.isSelected()) {
      return;
    }

    const jodGridApi = this.jodGridOptions?.api;
    if (jodGridApi == null) {
      return;
    }

    jodGridApi.deselectAll();

    const currentJoh = <JoHeader>e.node.data;
    const currentJohId = currentJoh.id;
    jodGridApi.forEachNode(node => {
      const jod = <JoDetail>node.data;
      if (jod.joHeaderId == currentJohId) {
        node.setSelected(true);
      }
    })
  }

  canDeleteJoHeaderFromBatch(joh: JoHeader) {
    return joh != null && joh.joStatusId === JoStatusCode.OpenProcessIncomplete;
  }

  async deleteJoHeaderFromBatch(joh: JoHeader) {
    await this.wrapPending(async () => {
      this.dialogService.toast({ message: 'Removing Job Order from Pull batch' });
      await this.joUtilsService.deleteJoHeaderFromBatch(joh.id);
      this.dialogService.toast({ message: 'Job Order removal complete' });
      await this.updateForBatch(this.joPullBatchId);
    });
  }

  applyItemDetails(jod: JoDetail) {
    this.router.navigate(['/jo-pull-apply', this.joPullBatchId, jod.id, this.shouldPrintBarcodes]);
  }

  async unApplyItemDetails(jod: JoDetail) {
    await UnapplyJodDialogComponent.show(this.matDialog, { joDetail: jod });

    this.johGridOptions.api.refreshCells();
    this.jodGridOptions.api.refreshCells();
  }

  async changeBatch() {
    const res = await ChangeJoPullBatchDialogComponent.show(this.matDialog, { joPullBatchId: this.joPullBatchId });
    if (res && res.joPullBatchId != null) {
      await this.updateForBatch(res.joPullBatchId);
    }
  }

  
  async markBatchComplete() {
    // TODO: check auth here
    const res = await this.dialogService.askYesNo('Mark Batch Complete', 'Are you sure?');
    if (res.index !== 0) {
      return;
    }
    // 2 steps
    // Mark all JoHeader records that are part of this batch with the 'next' status.
    // Create a collection of PurchaseOrderDetail records
    this.dialogService.toast({ message: 'Marking Batch Complete and creating order detail records' });
    this.joHeaders.forEach(joh => {
      joh.joStatusId = JoStatusCode.OpenProcessComplete;
    });

    let odCount = 0;
    const now = new Date();
    const poHeaderMap = {};
    // need to use for-of loop here because of async query inside. 
    for (const jod of this.joDetails) {
      if (jod.getRemainingQty() > 0) {
        // find or create the corresponding poHeader for each jod
        const manuf = jod.product.productType.manufacturer;
        let poHeader = poHeaderMap[manuf.id];
        if (poHeader == null) {
          const poStruct = {
            shippingWarehouseId: 1, // TODO: magic number for unitec
            manufacturerId: manuf.id,
            poStatusId: PoStatusCode.NewUnposted,
            poDate: DateFns.startOfDay(now),
            crtnTs: now
          };
          poHeader = this.uow.createEntity(PoHeader, poStruct)
          poHeaderMap[manuf.id] = poHeader;
        }
        // search for a ManufacturerProductTypeConfig that matches
        const mptConfig = await this.dbQueryService.findMatchingManufacturerProductTypeConfig(jod);
        const leadDays = manuf.leadTimeDays;
        const podStruct = {} as PoDetail;
        podStruct.poHeaderId = poHeader.id;
        podStruct.joDetailId = jod.id;
        podStruct.productId = jod.productId;
        podStruct.origProductId = jod.productId;
        podStruct.poDetailTypeId = 'ORDER';
        podStruct.orderQty = jod.getRemainingQty();
        podStruct.crtnTs = now;
        podStruct.expectedShipDate = DateFns.dateAdd(now, leadDays, 'days');
        
        // mptConfig may be null. 
        podStruct.manufacturerProductTypeConfigId = mptConfig?.id;
        const ord = this.uow.createEntity(PoDetail, podStruct);
        odCount++;
      }
    };
    const r = await this.dbSaveService.saveChanges();
    this.isBatchDone = true;
    // should update both grids
    this.johGridOptions.api.refreshCells();
    this.jodGridOptions.api.refreshCells();
    this.dialogService.toast({ message: `Created ${odCount} Purchase Order Detail record(s)` });
    this.goBack();
  }

  async uncompleteBatch() {
    const { ok, pohIdSet } = await this.joUtilsService.uncompleteBatch(this.joPullBatchId);
    
    if (ok) {
      this.poUtilsService.deleteStrandedPoHeaders(pohIdSet);
      this.isBatchDone = false;
    }

    this.johGridOptions.api.refreshCells();
    this.jodGridOptions.api.refreshCells();
    AgFns.autoSizeAllColumns(this.jodGridOptions)
    
  }

  canPrintItemDetailBarcodes() {
    return this.joDetails.some(jod => jod.itemDetails.some(itd => itd.barcodePrintStatusId == BarcodePrintStatusCode.None));
  }

  async printItemDetailBarcodes() {
    const itemDetails = _.flatMap(this.joDetails, jod => jod.itemDetails.filter(itd => itd.barcodePrintStatusId == BarcodePrintStatusCode.None));
    const changes = await this.barcodeService.printItemDetailBarcodesAndNewAddonBinLabels(itemDetails);
    if (changes.length > 0) {
      await this.dbSaveService.saveSelectedChanges(changes);
    }
  }

  hasAddonDocs(jodAddon: JoDetailAddon) {
    return jodAddon?.addon.addonDocMaps.length > 0;
  }

  async viewAddonDocs(jodAddon: JoDetailAddon) {
    const data: FileViewDialogData = { 
      title: 'View Addon Document',
      docs:  jodAddon.addon.addonDocMaps.map(x => x.addonDoc) 
    };
    await FileViewDialogComponent.show(this.matDialog, data);

  }

  goAddonBins() {
    this.router.navigate(['/addon-bins'] );
  }

  updateLocation() {
    
    const urlTree = this.router.createUrlTree([`/jo-pull-to-wip/${this.joPullBatchId}`], {
      queryParams: {
        shouldPrintBarcodes: this.shouldPrintBarcodes
      },
    });
    const url = urlTree.toString();
    this.domainService.location.replaceState(url);
  }

}
