import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { ActiveStatus, EOQType, Feature, ProductType, ProductTypeAlt, ProductTypeImage, Product, Manufacturer, ProductTypeFeatureMap, ProductFeatureChoice, _MigrationProductTypeChange } from 'app/model/entities/entity-model';
import { FileService } from 'app/shared/file.service';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { DomainBaseComponent } from '../shared/domain-base.component';
import { DomainService } from '../shared/domain.service';
import { AuthService } from 'app/shared/auth.service';
import { CellClickedEvent, GridOptions, RowSelectedEvent } from '@ag-grid-community/core';

import { AgCheckboxCellComponent } from 'app/shared/ag-checkbox-cell.component';
import { AgFns, GridState, ISortModel } from 'app/shared/ag-fns';
import { AltProductTypeDialogComponent } from './alt-product-type-dialog.component';
import { EditProductDialogComponent } from './edit-product-dialog.component';
import { EntityFns } from 'app/shared/entity-fns';
import { EOQTypeCode } from 'app/model/enums/eoq-type-code';
import { FeatureChoiceFinderDialogComponent } from './feature-choice-finder-dialog.component';
import { ImageHandlerComponent } from 'app/shared/image/image-handler.component';
import { FeatureFinderDialogComponent } from './feature-finder-dialog.component';
import { UniAgFns } from 'app/shared/uni-ag-fns';
import { ProductTypeFeatureChangeDialogComponent } from './product-type-feature-change-dialog.component';
import { MigrationType } from 'app/model/entities/migration-product-type-change';



@Component({
  selector: 'app-product-type',
  templateUrl: './product-type.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class ProductTypeComponent extends DomainBaseComponent implements OnInit {
  @ViewChild(ImageHandlerComponent) imageHandler?: ImageHandlerComponent;
  productType: ProductType;
  isAdding: boolean;
  canEditRootStyleInfo: boolean;


  title: string;
  allManufs: Manufacturer[];
  searchManufs: Manufacturer[];

  features: Feature[];
  selectedFeature?: Feature;
  
  activeStatuses: ActiveStatus[];

  eOQTypes: EOQType[];

  productGridOptions: GridOptions;
  products: Product[] = [];
  allowCombination: boolean;
  selectedProduct?: Product;

  altItsGridOptions: GridOptions;
  altProductTypes: ProductType[] = [];

  rowSelection: 'single' | 'multiple' = 'single';

  migrationProductTypeChange: _MigrationProductTypeChange;
  
  constructor(protected domainService: DomainService, protected route: ActivatedRoute, protected authService: AuthService,
    private matDialog: MatDialog, private http: HttpClient, private fileService: FileService
  ) {
    super(domainService);

    this.route.paramMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
  }

  async canDeactivate() {
    if (this.isBusy) return false;
    if (this.uow.hasChanges()) {
      const ynResult = await this.dialogService.askYesNo('Changes have not been saved', 'Do you want to save before exiting?');
      if (ynResult.index == 0) {
        const ok = await this.saveChanges();
        if (!ok) {
          return false;
        }
      } else {
        this.dbSaveService.rejectChanges();
      }
    }

    // need to cleanup any pending changes.
    this.uow.manager.rejectChanges();

    this.uow.clearEntities(Manufacturer);
    this.uow.clearEntities(ProductTypeAlt);
    this.uow.clearEntities(Product);
    this.uow.clearEntities(ProductTypeImage);
    return true;
  }

  async updateFromContext() {

    const productTypeId = this.route.snapshot.params['productTypeId'];
    this.isAdding = productTypeId == 'add';
    if (this.isAdding) {
      this.createNewProductType();
    } else {
      this.productType = await this.dbQueryService.getProductType(productTypeId);
      this.migrationProductTypeChange = await this.dbQueryService.getMigrationProductTypeChange(this.productType.id);
    }

    this.products = this.productType.products.slice();
    this.altProductTypes = await this.getAltProductTypes(this.productType);

    const user = this.authService.getUser();
    this.canEditRootStyleInfo = user.isProductAdmin || this.isAdding;

    this.title = this.isAdding ? 'Creating New Product Type' : 'Editing Product Type';
    const allManufs = await this.uow.queryAll(Manufacturer, 'Manufacturers');
    this.allManufs = _.sortBy(allManufs, m => m.name);

    this.features = await this.dbQueryService.getFeatures(true);

    this.eOQTypes = this.dbQueryService.getAllCached(EOQType);
    this.activeStatuses = this.dbQueryService.getAllCached(ActiveStatus);

    this.productGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onProductGridReady,
      onRowSelected: this.onProductRowSelected,
      rowSelection: 'single',
      rowMultiSelectWithClick: true,
      getRowId: (x) => {
        return x.data.id;
      }
    });

    this.altItsGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onAltItsGridReady
    });

    this.isPageReady = true;
  }

  private createNewProductType() {
    this.productType = this.uow.createEntity(ProductType, {
      modUserInit: this.authService.getUser().initials,
      eOQTypeId: EOQTypeCode.None,
      manufacturerId: null,
    });
  }

  async addFeatures() {
    const features = await FeatureFinderDialogComponent.show(this.matDialog, 
      { excludedFeatureIds: this.productType.productTypeFeatureMaps.map(x => x.featureId), rowSelection: 'single' }
    );
    if (features.length == 0) {
      return;
    }
    if (features.some(f => f.featureChoices.length == 0)) {
      await this.dialogService.showOkMessage('Missing Feature Choices', 'All selected features must have a least one feature choices');
      return;
    }

    const ptfMaps = features.map(f => {
      var displayOrder = 1;
      if (this.productType.productTypeFeatureMaps.length > 0) {
        displayOrder = _.max(this.productType.productTypeFeatureMaps.map(x => x.displayOrder)) ?? 1;
      }
      return this.uow.createEntity(ProductTypeFeatureMap, {
        productTypeId: this.productType.id,
        featureId: f.id,
        displayOrder: displayOrder
      } );
    });

    this.products = await this.dbQueryService.getProductsForProductType(this.productType.id);

    if (this.products.length > 0) {
      const featureChoices = await FeatureChoiceFinderDialogComponent.show(this.matDialog,
        { featureId: features[0].id, excludedFeatureChoiceIds: [], rowSelection: 'single' }
      );
      if (featureChoices.length == 0) {
        await this.dialogService.showOkMessage('Missing Feature Choice', 'You must select a feature choice');
        return;
      }
    
      // Need to add a feature choice to each product with this featureChoice
      
      this.products.forEach(p => {
        ptfMaps.forEach(ptfMap => {
          const pfc = this.uow.createEntity(ProductFeatureChoice, {
            productId: p.id,
            featureChoiceId: featureChoices[0].id,
            displayOrder: ptfMap.displayOrder
          });
        });
      })
      AgFns.refreshGrid(this.productGridOptions, this.products);
    }
   
  }

  async changeFeature(feature: Feature) {
    const migrationType = _MigrationProductTypeChange.getMigrationType(this.productType, this.migrationProductTypeChange);
    if (migrationType == MigrationType.Reverting) {
      await this.dialogService.showOkMessage('Ths migration is marked for reversion', `This change from Feature: '${this.migrationProductTypeChange.fromOrigFeatureName}'
        to '${this.migrationProductTypeChange.toOrigFeatureName}' has been marked for reversion.  No further mapping can be completed 
        until after the next migration.`);
      return;
    }
    if (migrationType != MigrationType.Pending && migrationType != MigrationType.None) {
      const ynResult = await this.dialogService.askYesNo(`Revert?`, `This change from Feature: '${this.migrationProductTypeChange.fromOrigFeatureName}'
       to '${this.migrationProductTypeChange.toOrigFeatureName}' has already been completed in a previous migration.
       Do you want to revert it? ( this will occur during the next migration)`);
      if (ynResult.index == 1) {
        return;
      }
      this.migrationProductTypeChange.toOrigFeatureName = 'XXX-' + this.migrationProductTypeChange.toOrigFeatureName;
      await this.dbSaveService.saveSelectedChanges([this.migrationProductTypeChange]);
      this.migrationProductTypeChange = await this.dbQueryService.getMigrationProductTypeChange(this.productType.id);
      return;
    }


    const x = await ProductTypeFeatureChangeDialogComponent.show(this.matDialog, 
      { 
        productTypeId: this.productType.id,
       }
    );
    this.migrationProductTypeChange = await this.dbQueryService.getMigrationProductTypeChange(this.productType.id);
    
  }

  

  get migrationPendingClassName() {
    
    const migrationType = _MigrationProductTypeChange.getMigrationType(this.productType, this.migrationProductTypeChange);

    if (migrationType == MigrationType.Pending) {
      return 'accent-yellow' ;
    } else if (migrationType == MigrationType.Complete) {
      return 'accent-green'
    } else if (migrationType == MigrationType.Reverting) {
      return 'accent-red';
    } else {
      return 'accent-blue';
    }
  }

  viewFeature(feature: Feature) {
    // TODO; we really want to navigate to a specific feature.
    this.router.navigate(['./feature']);
    return;
  }

  onAllowReorderFeatureChange() {
    AgFns.refreshGrid(this.productGridOptions, this.products);
  }

  // async onFeatureChanged(feature: Feature) {
  //   const x = feature;
  //   const choiceValueMap =  new Map(feature.featureChoices.map((x) => [x.choiceValue, x]));

  //   const missingFcs = this.productType.products.map(x => x.choiceValue).filter(x => !choiceValueMap.has(x));
  //   if (missingFcs.length > 0) {
  //     await this.dialogService.showOkMessage('Missing Feature Choice', `The selected feature '${feature.name}' is missing the following choiceValues: </br></br>` + missingFcs.join(', '));
  //     this.selectedFeature= this.productType.feature;
  //     return false;
  //   }

  //   // update each of the products to the new featureChoice
  //   this.productType.products.forEach(x => x.featureChoice = choiceValueMap.get(x.choiceValue));
  //   // then update the productType template.
  //   this.productType.feature = feature;
  //   AgFns.refreshGrid(this.productGridOptions, this.products);
  // }

  searchManufacturer(query: string) {
    this.searchManufs = this.allManufs.filter(m => m.name.toLowerCase().indexOf(query.toLowerCase()) > -1);
  }

  async onProductGridReady(evt: any) {
    const colDefs = [
      // { headerName: '', field: 'id', checkboxSelection: true  },
      UniAgFns.getProductSortOrderColDef(x => x.data, 'productSortOrder', false) , 
      { headerName: 'Features', field: 'featureChoicesExtract', filter: 'agTextColumnFilter'  },
      { headerName: 'Reorder Features', filter: 'agTextColumnFilter' ,
        valueGetter: params => {
          const prod = <Product> params.data;
          var r =  prod && prod.getReorderProduct().getFeatureChoicesExtract();
          if (r == prod.featureChoicesExtract) {
            return '[same]';
          } else {
            return r;
          }
        } 
      },
      { headerName: 'Status', field: 'activeStatus.name', },
      { headerName: 'Id', field: 'id', filter: 'agNumberColumnFilter' },
      { headerName: 'Product Type Id', field: 'productTypeId', filter: 'agNumberColumnFilter' },
      
    ];
    if (this.user.isProductAdmin) {
      const invColDefs = [
        { ...AgFns.createButtonProps('Combine', this.onCombine.bind(this), { label: 'Combine', canDisplay: this.canCombine.bind(this) }),
          colId: 'combine', width: 150
        },
        { ...AgFns.createButtonProps('', this.editProduct.bind(this), { label: 'Edit Product' }) },
      ]
      colDefs.splice(4, 0, ...invColDefs as any[]);
    }
    const sortModel = [{ colId: 'productSortOrder', sort: 'asc' }] as ISortModel;
    AgFns.initGrid(this.productGridOptions, colDefs, sortModel, true);
    this.onAllowCombinationChange();
  }

  async onAltItsGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Manufacturer', field: 'manufacturer.name', filter: 'agTextColumnFilter' },
      { headerName: 'Description', field: 'description', filter: 'agTextColumnFilter' },
      { headerName: 'Style', field: 'style', filter: 'agTextColumnFilter' },
      { headerName: 'Id', field: 'id', filter: 'agNumberColumnFilter' },
    ];
    if (this.user.isProductAdmin) {
      const invColDefs = [
        { ...AgFns.createButtonProps('', this.removeAltProductType.bind(this), { label: 'Remove Alternate SKU' }), minWidth: 180 },
      ]
      colDefs.splice(3, 0, ...invColDefs as any[]);
    }
    const sortModel = [
      { colId: 'manufacturer.name', sort: 'asc' },
      { colId: 'description', sort: 'asc' },
      { colId: 'style', sort: 'asc' },
    ] as ISortModel;
    AgFns.initGrid(this.altItsGridOptions, colDefs, sortModel);
  }

  async onTabChanged(evt: any) {
    const currentTabLabel = evt.tab.textLabel;
    // HACK: needed because AgGrid loses buttons when tabbing from one tab to another and then back.
    this.products = this.products.slice();
    // this.altProductTypes = this.altProductTypes.slice();
    AgFns.refreshGrid(this.altItsGridOptions, this.altProductTypes);
  }

  // --------------------------------------------------------------------

  async addProductTypeCopy() {
    this.isAdding = true;
    const oldProductType = this.productType;
    this.productType = <ProductType>EntityFns.cloneStruct(oldProductType, ['id', 'style', 'description', 'descriptionExt']);
    this.searchManufs = [oldProductType.manufacturer];
  }

  async onProductRowSelected(event: RowSelectedEvent) {
    if (!event.node.isSelected()) {
      return;
    }
    const prod = event.data as Product;
    if (prod == null) {
      return;
    }

    this.selectedProduct = prod;
  }

  onAllowCombinationChange() {
    this.productGridOptions.columnApi?.setColumnsVisible(['combine'], this.allowCombination);
    this.productGridOptions.columnApi?.setColumnWidth('combine', 115);
    this.rowSelection = this.allowCombination ? 'multiple' : 'single';
  }

  canCombine(combineIss: Product) {
    if (this.productGridOptions.api == null) {
      return false;
    }

    return combineIss?.id == this.selectedProduct?.id && this.productGridOptions.api.getSelectedRows().length > 1;
  }

  async onCombine(combineIss: Product, event?: CellClickedEvent, origEvent?: UIEvent) {
    // Needed to stop onRowSelected call from being made right after this call OR during any await call within this method.
    // The askIfOk call below is async and the OnRowSelected will get called during its execution which will in turn
    // deselect all of the rows except the current one.
    origEvent?.stopPropagation();

    // if (this.hasChanges()) {
    //   this.toastr.warning('Please save or undo before attempting to combine');
    //   return;
    // }

    if (combineIss == null) {
      return;
    }
    const fromIsss = (this.productGridOptions.api?.getSelectedRows() as Product[])
      .filter(x => x != combineIss);
    if (fromIsss.length < 1) {
      return;
    }


    const yn = await this.dialogService.askYesNo('Combine Item SKU/Sizes', 'Are you sure that you want to combine these sizes?');
    if (yn.index != 0) {
      return;
    }

    const fromIssIds = fromIsss.map(x => x.id);
    const count = await this.dbSaveService.combineProducts(fromIssIds, combineIss.id);
    this.dialogService.toast(`${count} record(s) updated as a result of combining these sizes`);
    await this.refreshProducts();
  }

  // -------------------------------------------------------------------------------

  async addProduct() {
    // if (this.productType.productTypeFeatureMaps.length == 0) {
    //   this.dialogService.showOkMessage('Unable to add product',' The product type must ')
    // }
    const r = await EditProductDialogComponent.show(this.matDialog, { productType: this.productType });
    if (r) {
      await this.refreshProducts();
    }
    return r;
  }

  // async addProducts() {
  //   const r = await FeatureChoiceFinderDialogComponent.show(this.matDialog, {
  //     featureId: this.productType.featureId,
  //     excludedFeatureChoiceIds: [...this.productType.products.map(x => x.featureChoiceId)]
  //    });
  //   if (r) {
  //     const entities = r.map(x => {
  //       return this.uow.createEntity(Product, {
  //         modTs: new Date(),
  //         modUserInit: this.user.initials,
  //         productTypeId: this.productType.id,
  //         featureChoiceId: x.id,
  //         reorderSize: x.choiceValue,
  //         eOQSize: x.choiceValue,
  //         activeStatusId: 1,
  //       });
  //     });
  //     await this.dbSaveService.saveSelectedChanges(entities);
  //     await this.refreshProducts();
  //   }
  //   return r;
  // }

  async editProduct(iss: Product) {
    const r = await EditProductDialogComponent.show(this.matDialog, { product: iss });
    if (r) {
      await this.refreshProducts();
    }
    return r;
  }

  private async refreshProducts() {
    this.products = await this.dbQueryService.getProductsForProductType(this.productType.id);
    AgFns.refreshGrid(this.productGridOptions, this.products);
  }

  // -----------------------------------------------------------------------------


  async getAltProductTypes(its: ProductType) {
    this.altProductTypes = await AgFns.busyGrid(this.altItsGridOptions, this.busyService, () => {
      return this.dbQueryService.getAltProductTypesForProductType(its.id);
    });
    return this.altProductTypes;
  }

  async addAltProductType() {
    await AltProductTypeDialogComponent.show(this.matDialog, { productType: this.productType });
    // refresh AltProductTypes
    this.altProductTypes = await this.getAltProductTypes(this.productType);
  }

  async removeAltProductType(altIts: ProductType) {
    const isaToRemove = this.productType.productTypeAlts.find(isa => isa.altProductTypeId === altIts.id);
    isaToRemove.entityAspect.setDeleted();
    await this.dbSaveService.saveChanges();
    this.altProductTypes = await this.getAltProductTypes(this.productType);
    this.dialogService.toast('Alternative Style removed.');
  }

  // --------------------------------------------------------------------------------

  // async editTemplate() {
  //   const templateId = this.productType.featureId;
  //   this.router.navigate(['/feature'], {
  //     queryParams: {
  //       key: templateId
  //     }
  //   });
  // }

  async cancelChanges() {
    this.uow.rollback();
    if (this.isAdding) {
      this.createNewProductType();
    }
    await this.refreshProducts();
  }

  async saveChanges() {
    if (this.productType.productTypeFeatureMaps.length == 0) {
      await this.dialogService.showOkMessage('Cannot save', 'You must have a least one feature for this product type');
      return false;
    }
    const ea = this.productType.entityAspect;
    if (!ea.validateEntity()) {
      const vErrors = ea.getValidationErrors();
      const errMsg = vErrors.map(ve => ve.errorMessage).join('<br>');
      await this.dialogService.showOkMessage('Cannot save - Validation Errors', errMsg);
      return false;
    }
    this.productType.modTs = new Date(Date.now());
    await this.imageHandler?.attachFiles();

    // this.featureChoiceGridOptions.api.stopEditing();
    const sr = await this.dbSaveService.saveChanges();
    // AgFns.refreshGrid(this.featureChoiceGridOptions, this.featureChoices);
    this.dialogService.toast('Changes saved');
    this.isAdding = false;
    return true;
  }

  /** Create the join entity between Image and parent entity */
  createImageJoinEntity(event: { imageId: number, isPrimary: boolean }): void {
    this.dbSaveService.uow.createEntity(ProductTypeImage, {
      productTypeId: this.productType.id,
      imageId: event.imageId,
      isPrimary: event.isPrimary
    });
  }

  
}
