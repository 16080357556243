import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuardService } from 'app/shared/auth-guard.service';
import { UserAccountsComponent } from './user-accounts.component';
import { SharedModule } from 'app/shared/shared.module';
import { UserAccountDialogComponent } from './user-account-dialog.component';
import { AppConfigDialogComponent } from './app-config-dialog.component';
import { EntityManagerProviderGuard } from 'app/shared/entity-manager-guard';
import { AccountFinderDialogComponent } from './account-finder-dialog.component';
import { AccountComponent } from './account.component';
import { CanDeactivateGuard } from 'app/shared/can-deactivate-guard.service';
import { EditAccountDialogComponent } from './edit-account-dialog.component';
import { QbConfigDialogComponent } from './qb-config-dialog.component';

const routes = [
  {
    path: 'user-accounts',
    component: UserAccountsComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
    // canDeactivate: [CanDeactivateGuard]
    data: {
      icon: 'person'
    }
  },
  {
    path: 'accounts',
    component: AccountComponent,
    canActivate: [AuthGuardService, EntityManagerProviderGuard],
    canDeactivate: [CanDeactivateGuard],
    data: {
      icon: 'business'
    }
  }
];


@NgModule({
  declarations: [
    UserAccountsComponent,
    UserAccountDialogComponent,
    AppConfigDialogComponent,
    AccountFinderDialogComponent,
    EditAccountDialogComponent,
    AccountComponent,
    QbConfigDialogComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
  ],
  exports: [

  ],
})

export class AdminModule {
}
