<div *ngIf="entity" class="ml-12">
  <div class="mt-12" uni-flex-row>
    <mat-form-field style="width: 50px">
      <input matInput placeholder="Saluation" [(ngModel)]="entity.empSalutation" [readonly]="true" />
    </mat-form-field>
    <mat-form-field style="width: 250px">
      <input matInput placeholder="First Name" [(ngModel)]="entity.empFirstName" [readonly]="true" />
    </mat-form-field>
    <mat-form-field style="width: 250px">
      <input matInput placeholder="Last Name" [(ngModel)]="entity.empLastName" [readonly]="true" />
    </mat-form-field>
  </div>

  <div class="mt-12" uni-flex-row>
    <mat-form-field style="width: 250px">
      <input matInput placeholder="Prepayment Amount" [(ngModel)]="entity.prepaymentAmt" [readonly]="true" />
    </mat-form-field>
    <mat-form-field style="width: 250px">
      <input matInput placeholder="Prepayment Card" [(ngModel)]="entity.prepaymentName" [readonly]="true" />
    </mat-form-field>
    <mat-form-field style="width: 250px">
      <input matInput placeholder="Prepayment Transaction" [(ngModel)]="entity.prepaymentTransactionId" [readonly]="true" />
    </mat-form-field>
  </div>
  
</div>
<div *ngIf="!entity">
  <div class="mt-12 ml-12">
    <h3>No Information Found</h3>
  </div>
</div>