import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Email, InvoiceHeader, JoHeader } from 'app/model/entities/entity-model';
import { InvoiceStatusCode } from 'app/model/enums/invoice-status-code';
import { BusyService } from 'app/shared/busy.service';
import { EmailService } from 'app/shared/email.service';
import { UnitOfWork } from 'app/shared/unit-of-work';
import { environment } from 'environments/environment';
import { UtilFns } from 'app/shared/util-fns';
import { DbSaveService } from 'app/shared/db-save.service';
import { DbQueryService } from 'app/shared/db-query.service';
import { PaymentService } from 'app/shared/payment.service';
import { TransactionPayment } from 'app/shared/payment-interfaces';
import { DocSubmitTypeCode } from 'app/model/enums/doc-submit-type-code';

export interface InvoicePrintDialogComponentData {
  invoiceHeader: InvoiceHeader;
  /** if we are not already on the preview page */
  canPreview: boolean;
  /** if we want to print all invoices for a shipment */
  // NOTE: For now we are ignoring this 
  // allInvoices: boolean;
}

@Component({
  templateUrl: './invoice-print-dialog.component.html',
  //  encapsulation: ViewEncapsulation.None
})
export class InvoicePrintDialogComponent {

  invoiceHeader: InvoiceHeader;
  relatedInvoiceHeaders: InvoiceHeader[];
  joHeader: JoHeader;
  emailAddress: string;
  message: string;
  email: Email;
  pdfurl: string;
  canPreview: boolean;
  isPrinting: boolean;
  isManifested: boolean;
  
  isPageReady = false;

  static async show(matDialog: MatDialog, data: InvoicePrintDialogComponentData) {
    
    return await matDialog
      .open(InvoicePrintDialogComponent, {
        disableClose: true,
        height: '340px',
        width: '800px',
        data: data,
      })
      .afterClosed()
      .toPromise();
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: InvoicePrintDialogComponentData, public dialogRef: MatDialogRef<InvoicePrintDialogComponent>,
    private emailService: EmailService, private router: Router, public busyService: BusyService, private paymentService: PaymentService,
    private dbQueryService: DbQueryService, private dbSaveService: DbSaveService, private uow: UnitOfWork) {

    this.invoiceHeader = data.invoiceHeader;
    this.canPreview = data.canPreview;
    
    this.prepare();
  }

  async prepare() {
    this.relatedInvoiceHeaders = await this.dbQueryService.getInvoicesOnSameShipment(this.invoiceHeader.id);
    // For now - insure that the invoiceHeader is the 'main' invoiceHeader.
    if (!this.invoiceHeader.isPrimaryInvoice && this.invoiceHeader.shipmentId != null) {
      this.invoiceHeader = this.relatedInvoiceHeaders.find(invh => invh.isPrimaryInvoice) ?? this.invoiceHeader;  
    }
    this.joHeader = this.invoiceHeader.joHeader;
    this.isManifested = this.joHeader.manifestId != null;
    this.emailAddress = this.joHeader.billingEmail && this.joHeader.billingEmail.trim();
    this.pdfurl = environment.apiRoot + 'api/pdf/pdffromurl/Invoice' + this.invoiceHeader.id + '?url=' + UtilFns.getBaseUrl() +
      'printable-invoice/' + this.invoiceHeader.id;
    // For now - always print ALL invoices in the shipment
    this.pdfurl += ';all=true';

    if (this.canChargeCard()) {
      const amt = UtilFns.fmtCurrency(this.invoiceHeader.calcTotalAmt(), 2);
      this.message = `Invoice amount ${amt} can be charged to P-Card ${this.joHeader.paymentName}`;
    } else if (this.invoiceHeader.paymentAmt) {
      this.message = `Invoice amount ${UtilFns.fmtCurrency(this.invoiceHeader.paymentAmt)} was charged to P-Card ${this.invoiceHeader.paymentName}`;
    }
    this.getEmailStatus();
    this.isPageReady = true;
  }

  canChargeCard(): boolean {
    return !!this.joHeader.paymentsCustomerId && !!this.joHeader.paymentProfileId && !this.invoiceHeader.paymentAmt;
  }

  async chargeCard() {
    const jo = this.joHeader;
    const amt = +this.invoiceHeader.calcTotalAmt().toFixed(2);
    try {
      const result = await this.paymentService.submitPayment(jo.paymentsCustomerId, jo.paymentProfileId, amt, 
        this.joHeader.customerPurchaseOrderNumber);
      const tx = result.transactionResponse as TransactionPayment;
      if (tx.errors) {
        this.setMessage(result);
      } else {
        this.message = `Payment successful.  Amount: ${amt},  Account Type: ${tx.accountType}  Account Number: ${tx.accountNumber}  Transaction ID: ${tx.transId}`;
        this.invoiceHeader.paymentAmt = amt;
        this.invoiceHeader.paymentName = jo.paymentName;
        this.invoiceHeader.totalAmt = this.invoiceHeader.calcTotalAmt();
        await this.dbSaveService.saveSelectedChanges([this.invoiceHeader]);
      }
    } catch (e: any) {
      this.setMessage(e);
    }
  }

  /** Set the message content using error messages from the result */
  private setMessage(result: any) {
    const msg = this.paymentService.getErrorMessage(result);
    this.message = msg;
  }

  canSendEmail(): boolean {
    const docSubmitTypeId = this.joHeader.account.invoiceDocSubmitTypeId;
    // must be either Email or both to allow this. 
    if (docSubmitTypeId == DocSubmitTypeCode.Print) {
      return false;
    }
    if (this.emailAddress == null || this.emailAddress.length == 0) {
      return false;
    }
    if (this.isManifested) {
      return false; // invoice is on manifest; can't send it separately
    }
    return UtilFns.validateEmail(this.emailAddress);
  }

  async sendEmail() {
    if (!this.canSendEmail()) {
      return;
    }
    await this.busyService.busy( async () => {

      const status = await this.emailService.sendInvoiceEmail(this.invoiceHeader.id, this.emailAddress);
      this.message = 'Email status: ' + status;

      if (status === 'accepted' || status === 'stored') {
        this.message = 'Email status: ' + status + '; checking for delivery';
        return this.getEmailStatus();
      }
    });
  }

  private async getEmailStatus() {
    const email = await this.emailService.getInvoiceEmailStatus(this.invoiceHeader.id);
    if (email) {
      this.email = email;
      this.message = 'Email status: ' + email.status;
    }
  }

  canPrintDocument() {
    if (this.canChargeCard()) {
      return false;
    }
    const docSubmitTypeId = this.joHeader.account.invoiceDocSubmitTypeId;
    // must be either Print or both to allow this. 
    if (docSubmitTypeId == DocSubmitTypeCode.Email) {
      return false;
    }
    return true;
  }

  printDocument() {
    const pwin = window.open(this.pdfurl, '_blank');
    if (this.invoiceHeader.invoiceStatusId !== InvoiceStatusCode.InvoicePosted) {
      setTimeout(() => {
        this.isPrinting = true;
        this.invoiceHeader.invoiceStatusId = InvoiceStatusCode.Printed;
        this.dbSaveService.saveSelectedChanges([this.invoiceHeader]);
      }, 1000);
    }
  }

  canPost() {
    // return this.invoiceHeader?.invoiceStatusId == InvoiceStatusCode.VoucherLabeled && !this.isManifested;
    return this.invoiceHeader?.invoiceStatusId == InvoiceStatusCode.Printed || this.invoiceHeader?.invoiceStatusId == InvoiceStatusCode.AwaitPost;
  }

  isPosted() {
    return this.invoiceHeader?.invoiceStatusId == InvoiceStatusCode.InvoicePosted;
  }

  getQuickbooksLink() {
    if (this.invoiceHeader?.invoiceStatusId === 'POSTED') {
      return environment.quickbooksUrl + 'invoice?txnId=' + this.invoiceHeader.gLInvoiceId;
    }
    return '';
  }

  // async fakePostInvoiceToGL() {
  //   await this.dbQueryService.checkRowVersion(this.invoiceHeader);
  //   await this.busyService.busy( async () => {
  //     try {
  //       this.relatedInvoiceHeaders.forEach( (invh) => {
  //         invh.gLInvoiceId = 'FAKE_GL_ENTRY'
  //         invh.invoiceStatusId = InvoiceStatusCode.InvoicePosted;
  //       });
  //       await this.dbSaveService.saveChanges();
        
  //     } catch(err) {
  //       this.dbSaveService.rejectChanges();
  //       console.log('Error posting invoice', err);
  //       this.emailStatus = 'Error posting invoice: ' + err.message;
  //     } finally {
  //       this.isPrinting = false;
  //     }
  //   })
  // }

  
  async postInvoiceToGL() {
    await this.dbQueryService.checkRowVersion(this.invoiceHeader);
    // Must post ALL of the invoices on this shipment OR NONE of them
    // This is handled on the server.
    const url = environment.apiRoot + `api/quickbooks/PostInvoiceToGL?invoiceHeaderId=${this.invoiceHeader.id}`;
    const query = this.uow.createQuery(InvoiceHeader, url);
    await this.busyService.busy( async () => {
      try {
        // NOTE: 'invoices' below should be the same as 'relatedInvoiceHeaders'
        // Server returns related headers, with updated glInvoiceId and invoiceStatusId
        // Query to update status in cache.
        const invoices = await query.execute();
      } catch(err) {
        this.dbSaveService.rejectChanges();
        console.log('Error posting invoice to ' + url, err);
        this.message = 'Error posting invoice: ' + err.message;
      } finally {
        this.isPrinting = false;
      }
    })
  }

  showPreview() {
    this.router.navigateByUrl('/printable-invoice/' + this.invoiceHeader.id);
    this.close();
  }

  close() {
    this.dialogRef.close(null);
  }

}
