import { HttpClient } from '@angular/common/http';
import { Component, inject, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySelectChange } from '@angular/material/legacy-select';
import { QBAccount } from 'app/model/entities/qb-account';
import { QBItem } from 'app/model/entities/qb-item';
import { AuthService, AuthUser } from 'app/shared/auth.service';
import { BusyService } from 'app/shared/busy.service';
import { DbQueryService } from 'app/shared/db-query.service';
import { DbSaveService } from 'app/shared/db-save.service';
import { DialogService } from 'app/shared/dialog.service';
import { UnitOfWork } from 'app/shared/unit-of-work';
import { environment } from 'environments/environment';
import { firstValueFrom } from 'rxjs';

/** Account objects returned from Quickbooks */
interface IntuitAccount {
  Id: string;
  Name: string;
  Classification: string;
  AccountType: string;
  AccountSubType: string;
}

/** Item objects returned from Quickbooks */
interface IntuitItem {
  Id: string;
  Name: string;
  Type: string;
  IncomeAccountRef: { name: string, Value: string };
}

interface AccountsAndItems {
  accounts: IntuitAccount[];
  items: IntuitItem[];
}

@Component({
  templateUrl: './qb-config-dialog.component.html',
})
export class QbConfigDialogComponent {
  qbAccounts: QBAccount[];
  qbItems: QBItem[];
  accountsAndItems: AccountsAndItems;
  isPageReady = false;
  user: AuthUser;
  private httpClient: HttpClient;

  static async show(matDialog: MatDialog, data: any) {
    
    return await matDialog
      .open(QbConfigDialogComponent, {
        disableClose: true,
        height: '80vh',
        width: '800px',
        data: data,
      })
      .afterClosed()
      .toPromise();
  }

  constructor(@Inject(MAT_DIALOG_DATA) private data: QbConfigDialogComponent,
    private dialogRef: MatDialogRef<QbConfigDialogComponent>, private dialogService: DialogService,
    public dbSaveService: DbSaveService, private dbQueryService: DbQueryService,
    public uow: UnitOfWork, private authService: AuthService, 
    public busyService: BusyService) {
    this.httpClient = inject(HttpClient);
    this.prepare();  
  }

  async prepare() {
    if (this.uow.hasChanges()) {
      this.dialogService.showOkMessage('Unable to open', 'Please save or cancel before trying this.');
      this.dialogRef.close(null);
      return;
    };
    
    this.user = this.authService.getUser();
    await this.loadData();
    this.isPageReady = true;
  }

  private async loadData() {
    const accountsUrl = environment.apiRoot + 'api/quickbooks/QueryAccountsAndItems';
    [this.qbAccounts, this.qbItems, this.accountsAndItems] = await Promise.all([
      this.dbQueryService.getQBAccounts(),
      this.dbQueryService.getQBItems(),
      firstValueFrom(this.httpClient.get<AccountsAndItems>(accountsUrl)),
    ]);
  }

  getAccountsByType(type: string) {
    const types = type.split('|');
    return this.accountsAndItems.accounts.filter(x => types.includes(x.AccountType));
  }

  getItemsByType(type: string) {
    const types = type.split('|');
    return this.accountsAndItems.items.filter(x => types.includes(x.Type));
  }

  onAccountChange(evt: MatLegacySelectChange, acct: QBAccount) {
    const id = evt.value;
    const selected = this.accountsAndItems.accounts.find(x => x.Id == id);
    if (selected) {
      acct.qbAccountId = id;
      acct.qbAccountName = selected.Name;
    }
  }

  onItemChange(evt: MatLegacySelectChange, item: QBItem) {
    const id = evt.value;
    const selected = this.accountsAndItems.items.find(x => x.Id == id);
    if (selected) {
      item.qbItemId = id;
      item.qbItemName = selected.Name;
    }
  }

  async save() {
    await this.dbSaveService.saveChanges();
    this.dialogRef.close(null);
  }
  
  async undo() {
    this.qbAccounts.slice().forEach(x => x.entityAspect.rejectChanges());
    this.qbItems.slice().forEach(x => x.entityAspect.rejectChanges());
    await this.loadData();
  }

  async close() {
    if (this.uow.hasChanges()) {
      const ynResult = await this.dialogService.askYesNo('Changes have not been saved', 'Do you want to save before exiting?');
      if (ynResult.index == 0) {
        await this.dbSaveService.saveChanges();
      } else {
        this.dbSaveService.rejectChanges();
      }
    }

    this.dialogRef.close(null);
  }

  
}
