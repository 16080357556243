import { GridOptions, RowDoubleClickedEvent, RowSelectedEvent } from '@ag-grid-community/core';
import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';


import { AgFns } from 'app/shared/ag-fns';
import { UtilFns } from 'app/shared/util-fns';
import * as _ from 'lodash';
import { DbQueryService } from './db-query.service';
import { DialogService } from './dialog.service';
import { Addon } from 'app/model/entities/addon';

export interface AddonFinderDialogData {
  accountId: number;
  rowSelection?: 'multiple' | 'single';
}

@Component({
  templateUrl: './addon-finder-dialog.component.html'
})
export class AddonFinderDialogComponent {

  UtilFns = UtilFns;
  mainGridOptions : GridOptions;
  selectedItem: Addon;
  allItems: Addon[];
  isServerSide: boolean;
  isPageReady = false;

  static async show(matDialog: MatDialog, data: AddonFinderDialogData) {
    const r = await matDialog.open(AddonFinderDialogComponent, {
      disableClose: true,
      height: '530px',
      width: '800px',
      data: data
    })
      .afterClosed().toPromise();
    return <Addon[]> r;
    
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: AddonFinderDialogData, public dialogRef: MatDialogRef<AddonFinderDialogComponent>,
    private dbQueryService: DbQueryService, private dialogService: DialogService) {

    this.isServerSide = this.data.accountId == null;
    this.prepare();
  }

  async prepare() {
    this.mainGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onGridReady,
      onRowSelected: this.onRowSelected,
      onRowDoubleClicked: this.onRowDoubleClick,
      onFirstDataRendered: p => p.api.sizeColumnsToFit(),
      singleClickEdit: true,
      rowSelection: this.data?.rowSelection ?? 'single',
      stopEditingWhenCellsLoseFocus: true,
      rowModelType: this.isServerSide? 'serverSide' : 'clientSide'
    });

    if (!this.isServerSide) {
      let allItems = await this.dbQueryService.getAddons(this.data.accountId)
      this.allItems = _.orderBy(allItems, ao => ao.nameAndLocation);
    } 
    this.isPageReady = true;
  }

  onGridReady(evt: any) {
    const colDefs = [
      { headerName: 'Addon', field: 'nameAndLocation' , checkboxSelection: this.data?.rowSelection == 'multiple', filter: 'agTextColumnFilter' },
      { headerName: 'Addon Station', field: 'addonStation.name', filter: 'agTextColumnFilter' },
      { headerName: 'Account', field: 'account.accountName', filter: 'agTextColumnFilter' },
      { headerName: 'Id', field: 'id', filter: 'agNumberColumnFilter' },
    ];

    const sortModel = [
      { colId: 'nameAndLocation', sort: 'asc' as const },
    ];
    AgFns.initGrid(this.mainGridOptions, colDefs, sortModel);
    if (this.isServerSide) {
      this.updateDatasource();
    }
  }

  updateDatasource() {
    const gridApi = this.mainGridOptions && this.mainGridOptions.api;
    if (gridApi == null) {
      return;
    }

    let q = this.dbQueryService.createAddonFinderQuery();
    
    const ds = AgFns.buildDatasource(() => q);
    gridApi.setServerSideDatasource(ds);
    
  }

  async onRowSelected(e: RowSelectedEvent) {
    // check if a deselect event and ignore
    if (!e.node.isSelected()) {
      return;
    }

    this.selectedItem = e.data as Addon;
  }

  onRowDoubleClick(e: RowDoubleClickedEvent) {
    if (!e.node.isSelected()) {
      return;
    }
    const addon = e.data as Addon;
    this.dialogRef.close([addon]);
  }

  ok() {
    const r = this.mainGridOptions.api.getSelectedRows()
    this.dialogRef.close(r);
  }

  cancel() {
    this.dialogRef.close([]);
  }

}
