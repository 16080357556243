// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { CreditMemoStatus } from './credit-memo-status';
import { CreditMemoType } from './credit-memo-type';
import { ReturnRequestDetail } from './return-request-detail';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class CreditMemo extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  creditMemoAmt: number;
  creditMemoStatusId: number;
  creditMemoTypeId: number;
  notes: string;
  returnRequestDetailId: number;
  transactionId: string;
  wasAmtOverridden: boolean;
  creditMemoStatus: CreditMemoStatus;
  creditMemoType: CreditMemoType;
  returnRequestDetail: ReturnRequestDetail;
}

