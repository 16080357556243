import { MetadataStore } from 'breeze-client';

import { Account } from './account';
import { AccountCryptonym } from './account-cryptonym';
import { ActiveStatus } from './active-status';
import { Addon } from './addon';
import { AddonBin } from './addon-bin';
import { AddonBinStationStatus } from './addon-bin-station-status';
import { AddonBinStatus } from './addon-bin-status';
import { AddonDoc } from './addon-doc';
import { AddonDocMap } from './addon-doc-map';
import { AddonImage } from './addon-image';
import { AddonStation } from './addon-station';
import { BarcodePrintStatus } from './barcode-print-status';
import { CancellationReasonType } from './cancellation-reason-type';
import { CancellationRequest } from './cancellation-request';
import { CancellationRequestDetail } from './cancellation-request-detail';
import { CancellationRequestDetailStatus } from './cancellation-request-detail-status';
import { CancellationRequestStatus } from './cancellation-request-status';
import { Config } from './config';
import { CreditMemo } from './credit-memo';
import { CreditMemoStatus } from './credit-memo-status';
import { DocSubmitType } from './doc-submit-type';
import { EOQType } from './eoq-type';
import { Email } from './email';
import { EntityIssue } from './entity-issue';
import { Feature } from './feature';
import { FeatureChoice } from './feature-choice';
import { GLAccount } from './gl-account';
import { GLSubmission } from './gl-submission';
import { GenId } from './gen-id';
import { Image } from './image';
import { InvoiceDetail } from './invoice-detail';
import { InvoiceHeader } from './invoice-header';
import { InvoiceStatus } from './invoice-status';
import { ItemBin } from './item-bin';
import { ItemDetail } from './item-detail';
import { ItemDetailAddon } from './item-detail-addon';
import { ItemDetailHist } from './item-detail-hist';
import { ItemDetailStatus } from './item-detail-status';
import { ItemReplacement } from './item-replacement';
import { ItemReplacementStatus } from './item-replacement-status';
import { ItemReplacementType } from './item-replacement-type';
import { JoDetail } from './jo-detail';
import { JoDetailAddon } from './jo-detail-addon';
import { JoExpectedShipDateHistory } from './jo-expected-ship-date-history';
import { JoHeader } from './jo-header';
import { JoHeaderPull } from './jo-header-pull';
import { JoNote } from './jo-note';
import { JoNoteType } from './jo-note-type';
import { JoPullBatch } from './jo-pull-batch';
import { JoStatus } from './jo-status';
import { Manifest } from './manifest';
import { ManifestGroup } from './manifest-group';
import { ManifestGroupInvoice } from './manifest-group-invoice';
import { Manufacturer } from './manufacturer';
import { ManufacturerCryptonym } from './manufacturer-cryptonym';
import { ManufacturerImage } from './manufacturer-image';
import { ManufacturerProductTypeConfig } from './manufacturer-product-type-config';
import { ManufacturerProductTypeConfigAddon } from './manufacturer-product-type-config-addon';
import { PoDetail } from './po-detail';
import { PoDetailCancel } from './po-detail-cancel';
import { PoDetailType } from './po-detail-type';
import { PoHeader } from './po-header';
import { PoNote } from './po-note';
import { PoNoteType } from './po-note-type';
import { PoStatus } from './po-status';
import { PoTrx } from './po-trx';
import { PoTrxHist } from './po-trx-hist';
import { Product } from './product';
import { ProductEOQ } from './product-eoq';
import { ProductFeatureChoice } from './product-feature-choice';
import { ProductType } from './product-type';
import { ProductTypeAlt } from './product-type-alt';
import { ProductTypeFeatureMap } from './product-type-feature-map';
import { ProductTypeImage } from './product-type-image';
import { ProductTypeTag } from './product-type-tag';
import { ProductTypeTagMap } from './product-type-tag-map';
import { ProxCombineItem } from './prox-combine-item';
import { ProxSoloSubmission } from './prox-solo-submission';
import { ProxSoloSubmissionStatus } from './prox-solo-submission-status';
import { ProxSubmission } from './prox-submission';
import { ProxSubmissionItem } from './prox-submission-item';
import { ProxSubmissionStatus } from './prox-submission-status';
import { QBAccount } from './qb-account';
import { QBItem } from './qb-item';
import { ReturnCreditType } from './return-credit-type';
import { ReturnReasonType } from './return-reason-type';
import { ReturnRequest } from './return-request';
import { ReturnRequestDetail } from './return-request-detail';
import { ReturnRequestDetailStatus } from './return-request-detail-status';
import { ReturnRequestStatus } from './return-request-status';
import { ShipBin } from './ship-bin';
import { ShipFrequency } from './ship-frequency';
import { Shipment } from './shipment';
import { ShippingBox } from './shipping-box';
import { Warehouse } from './warehouse';
import { WarningType } from './warning-type';
import { _MigrationFeatureChoiceChange } from './migration-feature-choice-change';
import { _MigrationFeatureChoiceCombine } from './migration-feature-choice-combine';
import { _MigrationProductTypeChange } from './migration-product-type-change';

export class UniformaxRegistrationHelper {

    static register(metadataStore: MetadataStore) {
        metadataStore.registerEntityTypeCtor('Account', Account);
        metadataStore.registerEntityTypeCtor('AccountCryptonym', AccountCryptonym);
        metadataStore.registerEntityTypeCtor('ActiveStatus', ActiveStatus);
        metadataStore.registerEntityTypeCtor('Addon', Addon);
        metadataStore.registerEntityTypeCtor('AddonBin', AddonBin);
        metadataStore.registerEntityTypeCtor('AddonBinStationStatus', AddonBinStationStatus);
        metadataStore.registerEntityTypeCtor('AddonBinStatus', AddonBinStatus);
        metadataStore.registerEntityTypeCtor('AddonDoc', AddonDoc);
        metadataStore.registerEntityTypeCtor('AddonDocMap', AddonDocMap);
        metadataStore.registerEntityTypeCtor('AddonImage', AddonImage);
        metadataStore.registerEntityTypeCtor('AddonStation', AddonStation);
        metadataStore.registerEntityTypeCtor('BarcodePrintStatus', BarcodePrintStatus);
        metadataStore.registerEntityTypeCtor('CancellationReasonType', CancellationReasonType);
        metadataStore.registerEntityTypeCtor('CancellationRequest', CancellationRequest);
        metadataStore.registerEntityTypeCtor('CancellationRequestDetail', CancellationRequestDetail);
        metadataStore.registerEntityTypeCtor('CancellationRequestDetailStatus', CancellationRequestDetailStatus);
        metadataStore.registerEntityTypeCtor('CancellationRequestStatus', CancellationRequestStatus);
        metadataStore.registerEntityTypeCtor('Config', Config);
        metadataStore.registerEntityTypeCtor('CreditMemo', CreditMemo);
        metadataStore.registerEntityTypeCtor('CreditMemoStatus', CreditMemoStatus);
        metadataStore.registerEntityTypeCtor('DocSubmitType', DocSubmitType);
        metadataStore.registerEntityTypeCtor('EOQType', EOQType);
        metadataStore.registerEntityTypeCtor('Email', Email);
        metadataStore.registerEntityTypeCtor('EntityIssue', EntityIssue);
        metadataStore.registerEntityTypeCtor('Feature', Feature);
        metadataStore.registerEntityTypeCtor('FeatureChoice', FeatureChoice);
        metadataStore.registerEntityTypeCtor('GLAccount', GLAccount);
        metadataStore.registerEntityTypeCtor('GLSubmission', GLSubmission);
        metadataStore.registerEntityTypeCtor('GenId', GenId);
        metadataStore.registerEntityTypeCtor('Image', Image);
        metadataStore.registerEntityTypeCtor('InvoiceDetail', InvoiceDetail);
        metadataStore.registerEntityTypeCtor('InvoiceHeader', InvoiceHeader);
        metadataStore.registerEntityTypeCtor('InvoiceStatus', InvoiceStatus);
        metadataStore.registerEntityTypeCtor('ItemBin', ItemBin);
        metadataStore.registerEntityTypeCtor('ItemDetail', ItemDetail);
        metadataStore.registerEntityTypeCtor('ItemDetailAddon', ItemDetailAddon);
        metadataStore.registerEntityTypeCtor('ItemDetailHist', ItemDetailHist);
        metadataStore.registerEntityTypeCtor('ItemDetailStatus', ItemDetailStatus);
        metadataStore.registerEntityTypeCtor('ItemReplacement', ItemReplacement);
        metadataStore.registerEntityTypeCtor('ItemReplacementStatus', ItemReplacementStatus);
        metadataStore.registerEntityTypeCtor('ItemReplacementType', ItemReplacementType);
        metadataStore.registerEntityTypeCtor('JoDetail', JoDetail);
        metadataStore.registerEntityTypeCtor('JoDetailAddon', JoDetailAddon);
        metadataStore.registerEntityTypeCtor('JoExpectedShipDateHistory', JoExpectedShipDateHistory);
        metadataStore.registerEntityTypeCtor('JoHeader', JoHeader);
        metadataStore.registerEntityTypeCtor('JoHeaderPull', JoHeaderPull);
        metadataStore.registerEntityTypeCtor('JoNote', JoNote);
        metadataStore.registerEntityTypeCtor('JoNoteType', JoNoteType);
        metadataStore.registerEntityTypeCtor('JoPullBatch', JoPullBatch);
        metadataStore.registerEntityTypeCtor('JoStatus', JoStatus);
        metadataStore.registerEntityTypeCtor('Manifest', Manifest);
        metadataStore.registerEntityTypeCtor('ManifestGroup', ManifestGroup);
        metadataStore.registerEntityTypeCtor('ManifestGroupInvoice', ManifestGroupInvoice);
        metadataStore.registerEntityTypeCtor('Manufacturer', Manufacturer);
        metadataStore.registerEntityTypeCtor('ManufacturerCryptonym', ManufacturerCryptonym);
        metadataStore.registerEntityTypeCtor('ManufacturerImage', ManufacturerImage);
        metadataStore.registerEntityTypeCtor('ManufacturerProductTypeConfig', ManufacturerProductTypeConfig);
        metadataStore.registerEntityTypeCtor('ManufacturerProductTypeConfigAddon', ManufacturerProductTypeConfigAddon);
        metadataStore.registerEntityTypeCtor('PoDetail', PoDetail);
        metadataStore.registerEntityTypeCtor('PoDetailCancel', PoDetailCancel);
        metadataStore.registerEntityTypeCtor('PoDetailType', PoDetailType);
        metadataStore.registerEntityTypeCtor('PoHeader', PoHeader);
        metadataStore.registerEntityTypeCtor('PoNote', PoNote);
        metadataStore.registerEntityTypeCtor('PoNoteType', PoNoteType);
        metadataStore.registerEntityTypeCtor('PoStatus', PoStatus);
        metadataStore.registerEntityTypeCtor('PoTrx', PoTrx);
        metadataStore.registerEntityTypeCtor('PoTrxHist', PoTrxHist);
        metadataStore.registerEntityTypeCtor('Product', Product);
        metadataStore.registerEntityTypeCtor('ProductEOQ', ProductEOQ);
        metadataStore.registerEntityTypeCtor('ProductFeatureChoice', ProductFeatureChoice);
        metadataStore.registerEntityTypeCtor('ProductType', ProductType);
        metadataStore.registerEntityTypeCtor('ProductTypeAlt', ProductTypeAlt);
        metadataStore.registerEntityTypeCtor('ProductTypeFeatureMap', ProductTypeFeatureMap);
        metadataStore.registerEntityTypeCtor('ProductTypeImage', ProductTypeImage);
        metadataStore.registerEntityTypeCtor('ProductTypeTag', ProductTypeTag);
        metadataStore.registerEntityTypeCtor('ProductTypeTagMap', ProductTypeTagMap);
        metadataStore.registerEntityTypeCtor('ProxCombineItem', ProxCombineItem);
        metadataStore.registerEntityTypeCtor('ProxSoloSubmission', ProxSoloSubmission);
        metadataStore.registerEntityTypeCtor('ProxSoloSubmissionStatus', ProxSoloSubmissionStatus);
        metadataStore.registerEntityTypeCtor('ProxSubmission', ProxSubmission);
        metadataStore.registerEntityTypeCtor('ProxSubmissionItem', ProxSubmissionItem);
        metadataStore.registerEntityTypeCtor('ProxSubmissionStatus', ProxSubmissionStatus);
        metadataStore.registerEntityTypeCtor('QBAccount', QBAccount);
        metadataStore.registerEntityTypeCtor('QBItem', QBItem);
        metadataStore.registerEntityTypeCtor('ReturnCreditType', ReturnCreditType);
        metadataStore.registerEntityTypeCtor('ReturnReasonType', ReturnReasonType);
        metadataStore.registerEntityTypeCtor('ReturnRequest', ReturnRequest);
        metadataStore.registerEntityTypeCtor('ReturnRequestDetail', ReturnRequestDetail);
        metadataStore.registerEntityTypeCtor('ReturnRequestDetailStatus', ReturnRequestDetailStatus);
        metadataStore.registerEntityTypeCtor('ReturnRequestStatus', ReturnRequestStatus);
        metadataStore.registerEntityTypeCtor('ShipBin', ShipBin);
        metadataStore.registerEntityTypeCtor('ShipFrequency', ShipFrequency);
        metadataStore.registerEntityTypeCtor('Shipment', Shipment);
        metadataStore.registerEntityTypeCtor('ShippingBox', ShippingBox);
        metadataStore.registerEntityTypeCtor('Warehouse', Warehouse);
        metadataStore.registerEntityTypeCtor('WarningType', WarningType);
        metadataStore.registerEntityTypeCtor('_MigrationFeatureChoiceChange', _MigrationFeatureChoiceChange);
        metadataStore.registerEntityTypeCtor('_MigrationFeatureChoiceCombine', _MigrationFeatureChoiceCombine);
        metadataStore.registerEntityTypeCtor('_MigrationProductTypeChange', _MigrationProductTypeChange);
    }
}
