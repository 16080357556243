// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { Account } from './account';
import { Addon } from './addon';
import { AddonStation } from './addon-station';
import { Feature } from './feature';
import { FeatureChoice } from './feature-choice';
import { Manufacturer } from './manufacturer';
import { ManufacturerProductTypeConfig } from './manufacturer-product-type-config';
import { Product } from './product';
import { ProductType } from './product-type';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class ActiveStatus extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  name: string;
  accounts: Account[];
  addonStations: AddonStation[];
  addons: Addon[];
  featureChoices: FeatureChoice[];
  features: Feature[];
  manufacturerProductTypeConfigs: ManufacturerProductTypeConfig[];
  manufacturers: Manufacturer[];
  productTypes: ProductType[];
  products: Product[];
}

