import { GridOptions, RowSelectedEvent } from '@ag-grid-community/core';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { AgFns } from 'app/shared/ag-fns';
import { takeUntil } from 'rxjs/operators';
import { Manufacturer, ManufacturerImage, ManufacturerProductTypeConfig } from '../model/entities/entity-model';
import { DomainBaseComponent } from '../shared/domain-base.component';
import { DomainService } from '../shared/domain.service';
import { EditManufacturerProductTypeConfigDialogComponent } from './edit-manufacturer-product-type-config-dialog.component';


@Component({
  selector: 'app-manufacturer-product-type-config',
  templateUrl: './manufacturer-product-type-config.component.html',
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class ManufacturerProductTypeConfigComponent extends DomainBaseComponent implements OnInit {
  

  mptConfigGridOptions: GridOptions;

  constructor(protected domainService: DomainService, protected route: ActivatedRoute, private matDialog: MatDialog) {
    super(domainService);

    this.route.queryParamMap.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateFromContext();
    });
  }

  async updateFromContext() {

    this.mptConfigGridOptions = AgFns.initGridOptions(this, {
      onGridReady: this.onConfigGridReady,
      onRowSelected: this.onRowSelected,
      onFilterChanged: this.onFilterChanged,
      rowModelType: 'serverSide',
      onPaginationChanged: (params) => {
        if (params.newPage) {
          this.updateLocation();
        }
      }
    });
    AgFns.captureGridRouteParams(this.mptConfigGridOptions, this.route, 'id');

    this.isPageReady = true;
  }

  async canDeactivate() {
    if (this.isBusy) return false;
    this.uow.clearEntities(Manufacturer);
    
    return true;
  }

  // This method is called AFTER ngAfterViewInit - this is important
  // because the ColDef's here reference TemplateRef's above that will not be resolved until here.
  onConfigGridReady(evt: any) {
    const colDefs = [
      {  ...AgFns.createButtonProps('Edit', this.editMptConfig.bind(this), { label: 'Edit'}), maxWidth: 70 },
      { headerName: 'Manufacturer', field: 'productType.manufacturer.name',  filter: 'agTextColumnFilter'  },
      { headerName: 'Description', field: 'productType.description', filter: 'agTextColumnFilter' },
      { headerName: 'SKU', field: 'productType.style', filter: 'agTextColumnFilter' },
      { headerName: 'Config Name', field: 'configName', filter: 'agTextColumnFilter' },
      { headerName: 'Config Description', field: 'configDescription', filter: 'agTextColumnFilter' },
      { headerName: 'Id', field: 'id', filter: 'agNumberColumnFilter', maxWidth: 100 },
      { headerName: 'Status', field: 'activeStatus.name', },
      // Not yet supported.
      // { ...AgFns.createButtonProps('foo', this.onDelete.bind(this), { label: 'Delete', icon: 'delete' }) }
    ];
    const sortModel = [
        { colId: 'name', sort: 'asc' as const },
      ];

    AgFns.initGrid(this.mptConfigGridOptions, colDefs, sortModel);

    this.updateDatasource();
    AgFns.applyGridRouteParams(this.mptConfigGridOptions);
  }

  updateDatasource() {
    const gridApi = this.mptConfigGridOptions.api;
    if (gridApi == null) {
      return;
    }

    const ds = AgFns.buildDatasource(() => this.dbQueryService.createManufacturerProductTypeConfigQuery());
    gridApi.setServerSideDatasource(ds);
  }

  async onRowSelected(e: RowSelectedEvent) {
    // check if a deselect event and ignore
    if (!e.node.isSelected()) {
      return;
    }
    let manuf = e.data;
    if (!manuf) {
      return;
    }
    this.updateLocation(manuf.id);
  }

  // No longer supported.
  // async onDelete(entity: Manufacturer) {
  //   const isInUse = await this.dbQueryService.checkIfInUse(entity, ProductType, 'manufacturerId');
  //   if (isInUse) {
  //     this.dialogService.showOkMessage('Unable to delete', 'This manufacturer is still being referenced.');
  //     return false;
  //   }
  //   const ynResult = await this.dialogService.askYesNo('Delete this manufacturer?', 'Are you sure?');
  //   if (ynResult.index != 0) return;
  //   if (entity.manufacturerCryptonym) {
  //     entity.manufacturerCryptonym.entityAspect.setDeleted();
  //   }


  //   entity.entityAspect.setDeleted();
  //   await this.dbSaveService.saveChanges();
  //   this.manufGridOptions.api.refreshServerSide();
  //   this.dialogService.toast('Deleted');
  // }

  onFilterChanged() {
    this.updateLocation();
  }

  updateLocation(key: any = null) {
    const urlTree = this.router.createUrlTree(['/manufacturer-product-type-configs']);
    const url = AgFns.buildGridRouteParamsUrl(urlTree, this.mptConfigGridOptions, key && key.toString());
    this.domainService.location.replaceState(url);
  }

  async editMptConfig(mptConfig: ManufacturerProductTypeConfig) {
    const r = await EditManufacturerProductTypeConfigDialogComponent.show(this.matDialog, { manufacturerProductTypeConfig: mptConfig });
    if (r) {
      this.mptConfigGridOptions.api.refreshCells();
    }
  }

  async addMptConfig() {
    const r = await EditManufacturerProductTypeConfigDialogComponent.show(this.matDialog, {  });
    if (r) {
      this.mptConfigGridOptions.api.refreshServerSide();
    }
  }

}
