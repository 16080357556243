// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { AccountCryptonym } from './account-cryptonym';
import { ActiveStatus } from './active-status';
import { Addon } from './addon';
import { AddonBin } from './addon-bin';
import { DocSubmitType } from './doc-submit-type';
import { EntityIssue } from './entity-issue';
import { ItemDetail } from './item-detail';
import { JoHeader } from './jo-header';
import { PoDetail } from './po-detail';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class Account extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  accountName: string;
  accountTaxExemptionNumber: string;
  activeStatusId: number;
  allowPartialShipments: boolean;
  allowReissuanceOrders: boolean;
  dueDateNumDays: number;
  gLAccountRef: string;
  invoiceDocSubmitTypeId: number;
  invoiceEmail: string;
  modTs: Date;
  oLD_ACC_KEY: string;
  proximityAccountId: string;
  rowVersion: any;
  startDate: Date;
  accountCryptonym: AccountCryptonym;
  activeStatus: ActiveStatus;
  addonBins: AddonBin[];
  addons: Addon[];
  entityIssues: EntityIssue[];
  invoiceDocSubmitType: DocSubmitType;
  itemDetails: ItemDetail[];
  joHeaders: JoHeader[];
  poDetails: PoDetail[];
}

