// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { ActiveStatus } from './active-status';
import { ManufacturerProductTypeConfigAddon } from './manufacturer-product-type-config-addon';
import { PoDetail } from './po-detail';
import { ProductType } from './product-type';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class ManufacturerProductTypeConfig extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  activeStatusId: number;
  configDescription: string;
  configName: string;
  isForStockOrdersOnly: boolean;
  productTypeId: number;
  activeStatus: ActiveStatus;
  manufacturerProductTypeConfigAddons: ManufacturerProductTypeConfigAddon[];
  poDetails: PoDetail[];
  productType: ProductType;
}

