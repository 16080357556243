<div *ngIf="invoiceHeader">
      <div uni-flex-row class="h1 mt-6">
        <span style="flex: auto">Unitec Distribution Systems</span>
        <span>Invoice</span>
      </div>
      <div uni-flex-row class="mt-6">
        <span style="flex: auto; font-style: italic">Innovative Apparel Distribution Systems<br />Tax Id:
          52-0551098</span>
        <span *ngIf="isPosted && !isManifested" class="h1" style="font-style: italic; color: gray">REPRINT</span>
        <span *ngIf="isManifested" class="h1" style="font-style: italic; color: gray">ON MANIFEST</span>
      </div>

      <div uni-flex-row class="mt-12 ml-12">
        <mat-form-field uni-flex-item class="no-underline">
          <textarea matInput matAutosize placeholder="Bill" [ngModel]="getBillingAddress()"
            readonly="readonly" cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3"></textarea>
        </mat-form-field>
        <mat-form-field uni-flex-item class="no-underline">
          <textarea matInput placeholder="Ship" [ngModel]="getAddress(invoiceHeader.joHeader, 'shipping')"
            readonly="readonly" cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3"
            cdkAutosizeMaxRows="6"></textarea>
        </mat-form-field>
      </div>

      <div uni-flex-row class="mt-8" >
        <mat-form-field style="width: 20%" >
          <input matInput placeholder="Invoice #" [ngModel]="invoiceHeader.id" readonly="readonly" />
        </mat-form-field>
        <mat-form-field style="width: 15%" >
          <input matInput placeholder="Invoice Date" [ngModel]="invoiceHeader.invoiceDate | date:'MM/dd/yyyy'"
            readonly="readonly" />
        </mat-form-field>
        <mat-form-field style="width: 15%">
          <input matInput placeholder="Due Date" [ngModel]="invoiceHeader.dueDate | date:'MM/dd/yyyy'" readonly="readonly" />
        </mat-form-field>
        <mat-form-field style="width: 20%">
          <input matInput placeholder="Your PO Number" [ngModel]="invoiceHeader.joHeader?.customerPurchaseOrderNumber"
            readonly="readonly" />
        </mat-form-field>
        <mat-form-field style="width: 15%" >
          <input matInput placeholder="Amount Due" [ngModel]="invoiceHeader.calcTotalAmt() | currency"
            readonly="readonly" />
        </mat-form-field>
        
      </div>

      <table class="grid-print">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th colspan="4" class="th-shade col-center line-left italic" style="font-style: italic">N e w</th>
            <th colspan="2" class="th-shade col-center line-left line-right" style="font-style: italic">R e i s s u e</th>
            <th></th>
          </tr>
          <tr>
            <th>Manufacturer</th>
            <th>Description</th>
            <th>Style</th>
            <th>Features</th>
            <th class="th-shade col-right line-left">Qty</th>
            <th class="th-shade col-right">Price</th>
            <th class="th-shade col-right">Oversize</th>
            <th class="th-shade col-right">Custom</th>

            <th class="th-shade col-right line-left">Qty</th>
            <th class="th-shade col-right line-right">Amt/Unit</th>
            <th class="col-right">Total</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let d of flatDetails">
            <tr>
              <td>{{d.mfr}}</td>
              <td>{{d.desc}}</td>
              <td>{{d.style}}</td>
              <td>{{d.featureChoicesExtract}}</td>
              <td class="col-right">{{d.qty}}</td>
              <td class="col-right">{{d.unitPrice}}</td>
              <td class="col-right">{{d.unitOs}}</td>
              <td class="col-right">{{d.unitCus}}</td>
              <td class="col-right">{{d.reQty}}</td>
              <td class="col-right">{{d.reAmt}}</td>
              <td class="col-right">{{d.total}}</td>
            </tr>
            <tr *ngFor="let m of d.embs">
              <td></td>
              <td class="td-shade">{{m.description}}</td>
              <td class="td-shade">{{m.additionalInfo}}</td>
              <td class="td-shade"></td>
              <td class="td-shade"></td>
              <td class="td-shade col-right">{{m.unitPrice}}</td>
              <td colspan="4" class="td-shade"></td>
              <td class="col-right td-shade">{{m.total}}</td>
            </tr>
          </ng-container>
          <tr class="bottom-row">
            <td colspan="4"></td>
            <td class="col-right">{{ invoiceHeader.calcExtendedQty() }}</td>
            <td colspan="3"></td>
            <td class="col-right">{{ invoiceHeader.calcExtendedReissueQty() }}</td>
            <td></td>
            <td class="col-right">{{ invoiceHeader.calcExtendedAmt() + invoiceHeader.calcExtendedReissueAmt() | currency}}</td>
          </tr>
        </tbody>
      </table>

      <div uni-flex-row class="mt-12" >
        <div style="width: 67%">
          <div uni-flex-row>
            <mat-form-field style="width: 90%">
              <input matInput placeholder="Employee" [ngModel]="employee" readonly="readonly" />
            </mat-form-field>
          </div>
          <div uni-flex-row>
            <mat-form-field style="width: 90%">
              <textarea matInput matAutosize placeholder="Freight" [ngModel]="invoiceHeader.shipFreightInfo" readonly="readonly"
              cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"></textarea>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field style="width: 90%">
              <input matInput placeholder="Shipped via" [ngModel]="serviceDisplayName" readonly="readonly" />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field style="width: 90%">
              <input matInput placeholder="Shipping Label" [ngModel]="labelApi" readonly="readonly" />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field style="width: 90%">
              <input matInput placeholder="Tax Exempt #" [ngModel]="invoiceHeader.joHeader.account.accountTaxExemptionNumber" readonly="readonly" />
            </mat-form-field>
          </div>
        </div>
        <div style="width: 30%; padding: 1.5rem; border: 1px solid black; align-self: flex-end;" class="grid-summary">
          <div class="row">
            <div>Subtotal:</div>
            <div>{{invoiceHeader.calcExtendedAmt() + invoiceHeader.calcExtendedReissueAmt() | currency}}</div>
          </div>
          <div class="row">
            <div>Tax:</div>
            <div>{{invoiceHeader.taxAmt || 0 | currency}}</div>
          </div>
          <div class="row">
            <div>Handling:</div>
            <div>{{invoiceHeader.orderHandlingAmt || 0 | currency}}</div>
          </div>
          <div class="row">
            <div>Freight:</div>
            <div>{{invoiceHeader.freightAmt || 0 | currency}}</div>
          </div>
          <div class="row">
            <div>Prepaid:</div>
            <div style="margin-right: -4px">({{invoiceHeader.prepaymentAmt || 0 | currency}})</div>
          </div>
          <div class="row" *ngIf="invoiceHeader.joHeader.paymentProfileId">
            <div>{{paymentName}}:</div>
            <div style="margin-right: -4px">({{invoiceHeader.paymentAmt || 0 | currency}})</div>
          </div>
          <div class="row" style="font-weight: bold;">
            <div>Amount Due:</div>
            <div>{{invoiceHeader.calcTotalAmt() | currency}}</div>
          </div>
        </div>
      </div>

</div>