<app-uni-dialog [parent]="this" title="Edit Account" [contentTemplate]="contentTemplate"
  [footerTemplate]="footerTemplate"></app-uni-dialog>



<ng-template #contentTemplate>
  <form #form="ngForm">
    <div uni-flex-row>
      <mat-form-field style="width: 400px;">
        <input matInput placeholder="Account name" [ngModel]="account.accountName" name="accountName" readonly="true" />
      </mat-form-field>
      <mat-form-field style="width: 175px;">
        <input matInput placeholder="Start Date" [matDatepicker]="picker" [(ngModel)]="account.startDate" name="startDate" readonly="true" />
        <mat-datepicker-toggle matSuffix [for]="picker" disabled></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>

    <div uni-flex-row>
      <mat-form-field style="width: 250px;">
        <mat-label>Doc Submit Type</mat-label>
        <mat-select disabled placeholder="Invoice Doc Submit Type" [(ngModel)]="account.invoiceDocSubmitTypeId" name="invoiceDocSubmitTypeId" readonly="true"  >
          <mat-option [value]="docSubmitType.id" *ngFor="let docSubmitType of docSubmitTypes">
            {{docSubmitType.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field style="width: 250px;" [matTooltip]="account.invoiceEmail">
        <input matInput placeholder="Invoice Email" type="email" [(ngModel)]="account.invoiceEmail" name="invoiceEmail" readonly="true"   />
      </mat-form-field>
    </div>

    <div uni-flex-row>

      <mat-checkbox disabled style="width: 250px;"  placeholder="Allow Reissuance Order" [(ngModel)]="account.allowReissuanceOrders" 
        [ngModelOptions]="{standalone: true}"  >
        Allow Reissuance Orders
      </mat-checkbox>
      <mat-checkbox disabled style="width: 250px;"  placeholder="Allow Partial Shipments" [(ngModel)]="account.allowPartialShipments" 
        [ngModelOptions]="{standalone: true}"  >
        Allow Partial Shipments
      </mat-checkbox>
      <mat-form-field style="width: 250px;">
        <input matInput placeholder="Due Date # Days" type="number" [(ngModel)]="account.dueDateNumDays" name="dueDateNumDays" readonly="true"  />
      </mat-form-field>
    </div>

    <div uni-flex-row>
      <mat-form-field style="width: 250px;">
        <input matInput placeholder="Id" [ngModel]="account.id" name="id" readonly="true" />
      </mat-form-field>
      <mat-form-field style="width: 250px;">
        <input matInput placeholder="Proximity Id" [ngModel]="account.proximityAccountId" name="proximityAccountId" readonly="true" />
      </mat-form-field>
      <mat-form-field style="width: 250px;">
        <input matInput placeholder="Tax Exemption #" [ngModel]="account.accountTaxExemptionNumber" name="accountTaxExemptionNumber" readonly="true" />
      </mat-form-field>
    </div>
    <div uni-flex-row>
      <mat-form-field style="width: 250px;">
        <input matInput placeholder="GL Account Ref" [ngModel]="account.gLAccountRef" name="gLAccountRef" readonly="true" />
      </mat-form-field>
    </div>
  </form>
</ng-template>

<ng-template #footerTemplate>
  <button mat-raised-button [disabled]="!canSave()" (click)="ok()">Ok
  </button>
  <button mat-raised-button (click)="cancel()">Cancel
  </button>
</ng-template>
