<app-uni-page [parent]="this" [pageTitle]="title" [bodyTemplate]="bodyTemplate"
  [footerTemplate]="footerTemplate"></app-uni-page>

<ng-template #bodyTemplate>

  <as-split *ngIf="isPageReady" unit="percent" direction="vertical">

    <as-split-area [size]="40">
      <div class="ml-12 mt-12 mr-12">
        <div uni-flex-row>
          <mat-form-field uni-flex-item >
            <input *ngIf="!isAdding" matInput placeholder="Manufacturer" [ngModel]="productType.manufacturer.name"
              readonly="readonly" />
            <mat-select *ngIf="isAdding" [(ngModel)]="productType.manufacturerId" placeholder="Manufacturer">
              <mat-option>
                <ngx-mat-select-search ngModel (ngModelChange)="searchManufacturer($event)" placeholder="Manufacturer"
                  [placeholderLabel]="'Find manufacturer...'" [noEntriesFoundLabel]="'no matching bank found'">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let manuf of searchManufs" [value]="manuf.id">
                {{manuf.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field  uni-flex-item [matTooltip]="productType.style">
            <input [disabled]="!this.user.isProductAdmin" matInput placeholder="SKU" [(ngModel)]="productType.style" [disabled]="!this.canEditRootStyleInfo" />
          </mat-form-field>
        </div>

        <div uni-flex-row>
          <mat-form-field uni-flex-item>
            <input [disabled]="!this.user.isProductAdmin" matInput placeholder="Description" [(ngModel)]="productType.description" />
          </mat-form-field>
          <mat-form-field style="width: 200px">
            <mat-label>Status</mat-label>
            <mat-select [disabled]="!this.user.isProductAdmin" [(ngModel)]="productType.activeStatusId" placeholder="Status">
              <mat-option [value]="activeStatus.id" *ngFor="let activeStatus of activeStatuses">
                {{activeStatus.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div uni-flex-row-no-gap>
          <div class="">
            <button  mat-raised-button class="accent-blue" style="margin-bottom: 20px;" (click)="addFeatures()"  >
              <span>Add Features</span>
            </button>
          </div>
          <ng-container *ngFor="let map of productType.getOrderedProductTypeFeatureMaps()">
            <mat-form-field style="padding-left: 25px;"  >
              <input readonly="true" placeholder="Feature" matInput [ngModel]="map.feature.name"  />
            </mat-form-field>
            <button  mat-raised-button mat-small class="accent-blue" style="margin-bottom: 20px;margin-left: 5px" (click)="viewFeature(map.feature)">
              <span>View</span>
            </button>
            <button  mat-raised-button mat-small [class]="migrationPendingClassName" style="margin-bottom: 20px;margin-left: 5px"
            
             (click)="changeFeature(map.feature)">
              <span>Change</span>
            </button>
            
          </ng-container>
          
        </div>

        

        
        <div uni-flex-row>
          <mat-form-field style="width: 200px">
            <mat-label>EOQ Type</mat-label>
            <mat-select [disabled]="!this.user.isProductAdmin" [(ngModel)]="productType.eOQTypeId" placeholder="EOQ Type">
              <mat-option [value]="eOQType.id" *ngFor="let eOQType of eOQTypes">{{eOQType.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field style="width: 150px">
            <input [disabled]="!this.user.isProductAdmin" type="number" matInput placeholder="Lead Time in days" [(ngModel)]="productType.leadTimeDays" />
          </mat-form-field>
          
          <mat-checkbox [disabled]="!user.isProductAdmin" style="width: 250px;" placeholder="Allow Reorder Feature Choices" 
            [(ngModel)]="productType.allowReorderFeatureChoices"  (ngModelChange)="onAllowReorderFeatureChange()" [ngModelOptions]="{standalone: true}" >
            Allow Reorder Feature Choices
          </mat-checkbox>
        </div>

        <div uni-flex-row>

          <mat-form-field style="width: 200px">
            <input [disabled]="!this.user.isProductAdmin" matInput currencyMask placeholder="Ordering Cost" [(ngModel)]="productType.orderingCostAmt" />
          </mat-form-field>
          <mat-form-field style="width: 250px">
            <input [disabled]="!this.user.isProductAdmin" matInput currencyMask placeholder="Unit Carrying Cost/Year"
              [(ngModel)]="productType.unitCarryingCostPerYearAmt" />
          </mat-form-field>
          <mat-form-field style="width: 200px">
            <input [disabled]="!this.user.isProductAdmin" matInput type="number" min="0" max="100" placeholder="Safety Stock Pct" style="text-align: right"
              [ngModel]="productType.safetyStockPerYearPctFormatted | number: '1.2-2'"
              (ngModelChange)="productType.safetyStockPerYearPctFormatted=$event" />
            <span matSuffix>%</span>
          </mat-form-field>
          <div style="width: 400px">
            <app-image-handler [isReadOnly]="!this.user.isProductAdmin" [oldImages]="productType.productTypeImages" (createJoinEntity)="createImageJoinEntity($event)"></app-image-handler>
          </div>
        </div>

        <div uni-flex-row>
          <mat-form-field uni-flex-item>
            <textarea [disabled]="!this.user.isProductAdmin" rows="7" matInput placeholder="Extended Description"
              [(ngModel)]="productType.descriptionExt"></textarea>
          </mat-form-field>

        </div>
      </div>
    </as-split-area>

    <as-split-area [size]="60">
      <mat-tab-group (selectedTabChange)="onTabChanged($event)">
        <mat-tab label="Available Products">
          <h3 class="ml-8 mb-2">Products
            <mat-checkbox [disabled]="!user.isProductAdmin" style="width: 250px;" placeholder="Allow Combination"
              [(ngModel)]="allowCombination" [ngModelOptions]="{standalone: true}"
              (ngModelChange)="onAllowCombinationChange()">
              Allow Combination
            </mat-checkbox>
          </h3>
          <app-ag-grid [gridOptions]="productGridOptions" [rowData]="products" [rowSelection]="rowSelection"
            style="height: calc(100% - 55px);"></app-ag-grid>

        </mat-tab>
        <mat-tab label="Alternate Product Types">
          <app-ag-grid [gridOptions]="altItsGridOptions" [rowData]="altProductTypes"></app-ag-grid>
        </mat-tab>
      </mat-tab-group>
    </as-split-area>

  </as-split>
</ng-template>

<ng-template #footerTemplate>
  <div class="left-buttons">
    <button mat-raised-button [disabled]="isBusy || this.isAdding || !this.user.isProductAdmin" (click)="addProduct()">
      <span>Add New Product</span>
    </button>
    <!-- <button mat-raised-button [disabled]="this.isAdding || !this.user.isProductAdmin" (click)="addProducts()">
      <span>Add Multiple New Products</span>
    </button> -->
    <button class="ml-3" mat-raised-button [disabled]="isBusy || this.isAdding || !this.user.isProductAdmin" (click)="addAltProductType()">
      <span>Add Alternate Product Type</span>
    </button>

    <button [disabled]="isBusy" *ngIf="this.uow.hasChanges()" mat-raised-button (click)="saveChanges()">
      <span>Save Changes</span>
    </button>
    <button [disabled]="isBusy" *ngIf="this.uow.hasChanges()" mat-raised-button (click)="cancelChanges()">
      <span>Cancel Changes</span>
    </button>
  </div>
  <div>
    <!-- <button mat-raised-button [disabled]="this.isAdding" (click)="editTemplate()">
      <span>Edit Template</span>
    </button> -->
    <button [disabled]="isBusy" mat-raised-button (click)="goBack()">
      <span>Go Back</span>
    </button>
  </div>
</ng-template>