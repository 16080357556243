// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { Account } from './account';
import { AddonBin } from './addon-bin';
import { CancellationRequest } from './cancellation-request';
import { InvoiceHeader } from './invoice-header';
import { JoDetail } from './jo-detail';
import { JoHeaderPull } from './jo-header-pull';
import { JoNote } from './jo-note';
import { JoStatus } from './jo-status';
import { Manifest } from './manifest';
import { ReturnRequest } from './return-request';
import { ShipFrequency } from './ship-frequency';

/// <code-import> Place custom imports between <code-import> tags
import * as _ from 'lodash';
import { EntityTypeAnnotation, EntityPropertyAnnotation } from '../entity-type-annotation';
import { Validator } from 'breeze-client';
/// </code-import>

export class JoHeader extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  static getEntityTypeAnnotation(): EntityTypeAnnotation {
    const propAnnotations = [
      // new EntityPropertyAnnotation('leadTimeToShipNumDays', {
      //   displayName: 'Lead Time to Ship (Days)',
      //   validators: [ BaseEntity.greaterThanZeroValidator ]
      // }),
      new EntityPropertyAnnotation('taxRate', {
        validators: [ BaseEntity.createLessThanValidator(.35) ]
      }),
    ];

    return new EntityTypeAnnotation({
      validators: [  ],
      propertyAnnotations: propAnnotations
    });

  }

  get shippingKey() {
    // doesAccountPayFreight id is added because we will not allow different doesAccountPayFreights to ship together.
    return this.accountId.toString() + this.shippingName 
      + '|'  + this.shippingAddress1 + '|' + (this.shippingAddress2 ?? '')
      + '|'  + this.shippingCity + '|' + this.shippingState 
      + '|' + this.shippingZipCode 
      + '|' + this.doesAccountPayFreight
      + '|' + this.shipFrequencyId;
  }

  // conceptual Note interface
  getNotes() {
    return this.joNotes;
  }
  //


  // next three gets require that associated joDetails have been fetched.
  // getShipQty() {
  //   return _.sumBy(this.joDetails, jod => jod.shipQty);
  // }

  // getCancelAndReturnQty() {
  //   return _.sumBy(this.joDetails, jod => jod.cancelQty + jod.returnQty);
  // }

  getReturnQty() {
    return _.sumBy(this.joDetails, jod => jod.returnQty);
  }

  getCancelQty() {
    return _.sumBy(this.joDetails, jod => jod.cancelQty);
  }


  getOrderQty() {
    return _.sumBy(this.joDetails, jod => jod.orderQty);
  }

  getFormalEmployeeName() {
    return this.empLastName.trim() + ', ' + this.empFirstName.trim();
  }

  /** Return total creditMemoAmt of all credit memos applied to this JoHeader.  
   * Requires that joHeader.returnRequests.returnRequestDetails.creditMemos have been fetched. */
  getCreditMemoTotal() {
    const sum = this.returnRequests.reduce((sum, x) => x.returnRequestDetails.reduce((sum, x) => x.creditMemos.reduce((sum, x) => sum + x.creditMemoAmt, 0), 0), 0);
    return sum;
  }
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  accountId: number;
  allowPartialShipments: boolean;
  billingAddress1: string;
  billingAddress2: string;
  billingCity: string;
  billingEmail: string;
  billingExt: string;
  billingName: string;
  billingState: string;
  billingTelephone: string;
  billingZipCode: string;
  crtnTs: Date;
  customerPurchaseOrderNumber: string;
  discountAmt: number;
  doesAccountPayFreight: boolean;
  empEmail: string;
  empFirstName: string;
  empLastName: string;
  empSalutation: string;
  handlingChargePerOrderAmt: number;
  handlingChargePerShipmentAmt: number;
  joDate: Date;
  joStatusId: string;
  manifestId: number;
  oLD_CUS_KEY: number;
  oLD_PRO_KEY: number;
  origExpectedShipDate: Date;
  paymentName: string;
  paymentProfileId: string;
  paymentsCustomerId: string;
  prepaymentAmt: number;
  prepaymentName: string;
  prepaymentSalesReceiptId: string;
  prepaymentTransactionId: string;
  proximityJoHeaderId: string;
  rowVersion: any;
  shipFrequencyId: number;
  shippingAddress1: string;
  shippingAddress2: string;
  shippingCity: string;
  shippingExt: string;
  shippingName: string;
  shippingState: string;
  shippingTelephone: string;
  shippingZipCode: string;
  shouldChargeFreightOnFirstShipmentOnly: boolean;
  taxRate: number;
  account: Account;
  addonBins: AddonBin[];
  cancellationRequests: CancellationRequest[];
  invoiceHeaders: InvoiceHeader[];
  joDetails: JoDetail[];
  joHeaderPull: JoHeaderPull;
  joNotes: JoNote[];
  joStatus: JoStatus;
  manifest: Manifest;
  returnRequests: ReturnRequest[];
  shipFrequency: ShipFrequency;
}

